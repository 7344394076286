import React from "react";
import { Card, Skeleton, Stack } from "@mui/material";
import FeedCardActions from "../cards/components/FeedCardActions";

function FeedCardSkeleton() {
  return (
    <Card sx={{ p: 4 }}>
      <Stack
        direction="column"
        justifyContent="flex-start"
        alignItems="flex-start"
        spacing={2}
        width={"100%"}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="flex-start"
          spacing={4}
          width={"100%"}
        >
          <Stack
            direction="row"
            justifyContent="flex-start"
            alignItems="center"
            spacing={4}
            width={"75%"}
          >
            <Skeleton variant="circular" width={40} height={40} />
            <Stack
              direction="column"
              justifyContent="flex-start"
              alignItems="flex-start"
              spacing={2}
              width={"100%"}
            >
              <Skeleton width="50%" />
              <Skeleton width="33%" />
              <Skeleton width="40%" />
            </Stack>
          </Stack>
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-end"
            spacing={2}
            width={"25%"}
          >
            <Skeleton variant="text" width="50%" />
            <Skeleton width="50%" />
          </Stack>
        </Stack>
        <Skeleton variant="rectangular" width="100%" height={249} />
        <Skeleton
          variant="rectangular"
          width="33%"
          height={32}
          sx={{ my: 4 }}
        />
        <FeedCardActions
          handleDiscard={() => {}}
          handleEdit={() => {}}
          handleExpand={() => {}}
        />
      </Stack>
    </Card>
  );
}

export default FeedCardSkeleton;
