import {COLORS} from "../../../constants/base";

export const LEGEND_COLORS = [
  COLORS.brandYellow,
  COLORS.accentBlue,
  COLORS.green,
  COLORS.purple,
  '#e09f3e',
  '#b8c0ff',
  '#ff006e',
  '#0077b6',
  '#588157',
  '#dc2f02',
  '#7209b7',
  '#ff99c8',
  '#168aad',
  '#ff6b35',
  '#f8c630',
]

export const CHART_MODE = {
  explore: 'explore',
  snapshot: 'snapshot',
}

export const ROUND_BY_DIRECTION = {
  vertical: {
    topRight: true,
    bottomRight: true,
  },
  horizontal: {
    topLeft: true,
    topRight: true,
  }
}

export const FILTER_FIELDS = ['Metric', 'Label', 'Date', 'Sites', 'Food']
