import {TextField } from "@mui/material";
import { AnswerType } from "./questions";
import MultiLayout from "./MultiLayout";
import Dropdown from "./Dropdown";
import MuiPhone from "../inputs/MuiPhone";

export const FIELD_KEY = 'answer'

export const renderHandlerByType = (question, value, onChange, errors, setFieldValue, setErrors) => ({
  [AnswerType.Text]: <TextField
    placeholder="Type here..."
    label={question.question}
    name={FIELD_KEY}
    value={value}
    onChange={onChange}
    InputLabelProps={{ shrink: true }}
    helperText={errors[FIELD_KEY]}
    error={errors[FIELD_KEY]}
  />,
  [AnswerType.Number]: <MuiPhone
    helperText={errors[FIELD_KEY]}
    error={errors[FIELD_KEY]}
    placeholder="Type here..."
    label={question.question}
    name={FIELD_KEY}
    value={value}
    onChange={(data) => setFieldValue(FIELD_KEY, data)}
    InputLabelProps={{ shrink: true }}
  />,
  [AnswerType.Dropdown]: <Dropdown value={value} onChange={onChange} question={question} name={FIELD_KEY} errors={errors} setErrors={setErrors} />,
  [AnswerType.Radio]: <MultiLayout question={question} name={FIELD_KEY} value={value} onChange={onChange} />,
})


