import {configureStore} from "@reduxjs/toolkit";
import { reducer } from "./reducer";
import {resetSites} from "./sites";
import {resetFoods} from "./foods";
import {resetFilters} from "./explore";
import {removeProfile} from "./profile";

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false })
});

export const setupStore = (preloadedState) => {
  return configureStore({
    reducer,
    preloadedState,
  });
};

export const resetStore = () => dispatch => {
  dispatch(resetSites())
  dispatch(resetFoods())
  dispatch(resetFilters())
  dispatch(removeProfile())
}
