import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";

import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { TextField } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { Alert } from "../styled/GlobalStyled";
import { COLORS } from "../../constants/base";
import useAuth from "../../hooks/useAuth";

function ResetPasswordComplete(props) {
  const { token, uid64 } = props;
  const navigate = useNavigate();
  const { resetPasswordComplete } = useAuth();

  const { enqueueSnackbar } = useSnackbar();
  const [showPass, setShowPass] = useState(false);

  const handleClickShowPassword = () => {
    setShowPass((prevShowPass) => !prevShowPass);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
        token,
        uid64,
        submit: false,
      }}
      validationSchema={Yup.object().shape({
        password: Yup.string()
          .min(8, "Must be at least 12 characters")
          .max(255)
          .required("Required"),
        confirmPassword: Yup.string().when("password", {
          is: (val) => !!(val && val.length > 0),
          then: Yup.string().oneOf(
            [Yup.ref("password")],
            "Both password need to be the same"
          ),
        }),
      })}
      onSubmit={async (values, { setErrors, setStatus, setSubmitting }) => {
        try {
          const { status } = await resetPasswordComplete(
            values.password,
            values.token,
            values.uid64
          );
          if (status === 200) {
            enqueueSnackbar("Password successfully reset.", {
              variant: "success",
            });
            navigate("/auth/sign-in");
          } else {
            enqueueSnackbar("Oops, something went wrong.", {
              variant: "error",
            });
          }
        } catch (error) {
          const message = error.error || "Something went wrong";

          setStatus({ success: false });
          setErrors({ submit: message });
          setSubmitting(false);
        }
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={1} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <Box sx={{ position: "relative" }}>
            <TextField
              inputProps={{ maxLength: 42 }}
              InputLabelProps={{ shrink: true }}
              type={showPass ? "text" : "password"}
              name="password"
              label="Password"
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              fullWidth
              helperText={touched.password && errors.password}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <IconButton
              sx={{
                position: "absolute",
                top: 38,
                right: 0,
                color: COLORS.gray_icon,
              }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
          <Box sx={{ position: "relative" }}>
            <TextField
              inputProps={{ maxLength: 42 }}
              InputLabelProps={{ shrink: true }}
              type={showPass ? "text" : "password"}
              name="confirmPassword"
              label="Confirm password"
              value={values.confirmPassword}
              error={Boolean(touched.confirmPassword && errors.confirmPassword)}
              fullWidth
              helperText={touched.confirmPassword && errors.confirmPassword}
              onBlur={handleBlur}
              onChange={handleChange}
              my={3}
            />
            <IconButton
              sx={{
                position: "absolute",
                top: 38,
                right: 0,
                color: COLORS.gray_icon,
              }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
            >
              {showPass ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </Box>
          <Button
            sx={{ marginTop: "30px", height: "50px" }}
            type="submit"
            fullWidth
            variant="contained"
            color="secondary"
            disabled={isSubmitting}
          >
            Set password
          </Button>
        </form>
      )}
    </Formik>
  );
}

ResetPasswordComplete.propTypes = {
  token: PropTypes.string.isRequired,
  uid64: PropTypes.string.isRequired,
};

export default ResetPasswordComplete;
