import React, {useState} from 'react';
import FormLayout from "../../components/auth/FormLayout";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import {COLORS} from "../../constants/base";
import {useFormik} from "formik";
import useAppSelector from "../../hooks/useAppSelector";
import {selectUser} from "../../redux/user";
import useAppDispatch from "../../hooks/useAppDispatch";
import {useNavigate} from "react-router-dom";
import {CUSTOM_ANSWER, ONBOARDING_QUESTIONS, OTHER_ANSWER} from "../../components/onboarding/questions";
import {FIELD_KEY, renderHandlerByType} from "../../components/onboarding/handlers";
import * as Yup from "yup";
import userService from '../../services/UserService'
import {useSnackbar} from "notistack";
import {errorMessage} from "../../utils/axios";

const initialValues = {
    [FIELD_KEY]: '',
    [CUSTOM_ANSWER]: '',
  }
const Onboarding = () => {
  const [questions, setQuestions] = useState(ONBOARDING_QUESTIONS)
  const [answers, setAnswers] = useState({})
  const navigate = useNavigate()
  const user = useAppSelector(selectUser)
  const dispatch = useAppDispatch()
  const currentQuestion = questions[0]
  const isLastQuestion = questions.length === 1
  const { enqueueSnackbar } = useSnackbar()
  const [prevQuestions, setPrevQuestions] = useState([])

  const finishOnboarding = (payload = answers) => dispatch(
    userService.updateUser(user.id, {...payload, is_onboarded: true})
  ).catch((error) => enqueueSnackbar(errorMessage(error), { variant: "error"}))
  const next = ({ answer, [CUSTOM_ANSWER]: customAnswer }) => {
    const restQuestions = [...questions]
    const answered = restQuestions.shift()
    setPrevQuestions([...prevQuestions, answered])
    const answerToSend = answer === answered.customAnswerOn ? customAnswer : answer
    const updatedAnswers = {...answers, [answered.field]: answerToSend }
    setAnswers(updatedAnswers)
    if(isLastQuestion) {
      finishOnboarding(updatedAnswers)
      return;
    }
    setQuestions(restQuestions)
    resetForm({ values: initialValues })
  }
  const { handleChange, handleSubmit, resetForm, values, errors, setErrors, setFieldValue } = useFormik({
    initialValues,
    onSubmit: next,
    validationSchema: Yup.object().shape({
      answer: currentQuestion.validation,
      [CUSTOM_ANSWER]: Yup.string().when('answer', {
        is: OTHER_ANSWER,
        then: Yup.string().max(100, 'Answer can not be more than 100 characters').required("Answer is required"),
      })
    }),
    validateOnChange: false,
  })
  const skip = () => {
    if(isLastQuestion) {
      finishOnboarding()
      return;
    }
    setQuestions(questions.filter(({question}) => question !== currentQuestion.question))
    setPrevQuestions([...prevQuestions, currentQuestion])
    resetForm({ values: initialValues })
  }

  const goBack = () => {
    const prevQuestionsCopy = [...prevQuestions]
    const prevQuestion = prevQuestionsCopy.pop()
    setQuestions([prevQuestion, ...questions])
    setPrevQuestions(prevQuestionsCopy)
    resetForm({ values: {[FIELD_KEY]: answers[prevQuestion.field]}})
  }

  return (
    <FormLayout showBackButton={!!prevQuestions.length} onBackButtonClick={goBack} title="Firstly, a few questions" headTitle="Onboarding">
      <form onSubmit={handleSubmit}>
        <Stack spacing={5}>
          {renderHandlerByType(currentQuestion, values[FIELD_KEY], handleChange, errors, setFieldValue, setErrors)[currentQuestion.type]}
          <Button disabled={!values[FIELD_KEY]} type='submit' variant="contained" color="secondary">
            {isLastQuestion ? 'Done' : "Next →"}
          </Button>
          <Button
            type="button"
            sx={{
              color: COLORS.darkBlue2,
              fontSize: "13px",
              fontWeight: 400,
              padding: 0,
              textDecoration: 'underline',
              alignSelf: 'flex-start',
            }}
            onClick={skip}
          >
            Skip this Question
          </Button>
        </Stack>
      </form>
    </FormLayout>
  );
};

export default Onboarding;
