import React, { useState, useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";
import { useSnackbar } from "notistack";
import getEvents from "./axios/getEvents";
import getLeftTime from "./axios/getLeftTime";
import { fetchFoods } from "../../redux/foods";
import secondsToTime from "../labelling/utils/secondsToTime";

function WithVerification(Component) {
  // eslint-disable-next-line react/display-name
  return function () {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [param, setParam] = useState(true);
    const [event, setEvent] = useState(null);
    const [previousEvent, setPreviousEvent] = useState(null);
    const [loadingEvent, setLoadingEvent] = useState(true);
    const [loadingPreviousEvent, setLoadingPreviousEvent] = useState(true);
    const [leftTime, setLeftTime] = useState(null);
    const [progress, setProgress] = useState(0);
    const [alert, setAlert] = useState(false);
    const completedTasks = leftTime && leftTime.total_events - leftTime.irl;
    const titleToEnd = leftTime && secondsToTime(leftTime.alt * leftTime.irl);

    const reset = useCallback(() => {
      setEvent(null);
      setPreviousEvent(null);
    }, []);

    useEffect(() => {
      dispatch(fetchFoods({ labelling: true }));
    }, [dispatch]);

    useEffect(async () => {
      if (!event) {
        await getEvents(
          param,
          setParam,
          setEvent,
          setPreviousEvent,
          setLoadingEvent,
          setLoadingPreviousEvent,
          setAlert
        );
        if (!leftTime && param) {
          const res = await getLeftTime();
          if (res.status === 200) {
            setLeftTime(res.data);
          } else {
            setLeftTime(null);
            enqueueSnackbar("Oops, something went wrong.", {
              variant: "error",
            });
          }
        }
      }
    }, [event, reset, param]);

    useEffect(() => {
      if (leftTime) {
        const taskProgress =
          ((leftTime.total_events - leftTime.irl) * 100) /
          leftTime.total_events;

        if (Number.isNaN(taskProgress)) {
          setProgress(100);
        } else {
          setProgress(taskProgress);
        }
      }
    }, [leftTime]);

    return (
      <Component
        event={event}
        previousEvent={previousEvent}
        loadingEvent={loadingEvent}
        setLoadingEvent={setLoadingEvent}
        loadingPreviousEvent={loadingPreviousEvent}
        setLoadingPreviousEvent={setLoadingPreviousEvent}
        setLeftTime={setLeftTime}
        leftTime={leftTime}
        completedTasks={completedTasks}
        titleToEnd={titleToEnd}
        progress={progress}
        reset={reset}
        alert={alert}
      />
    );
  };
}

export default WithVerification;
