import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import PropTypes from "prop-types";
import DataCircularLoader from "../progress/DataCircularLoader";

function DataGridTable({ data, isMobile, columns, loading, isOwner, getRowHeight, getRowSpacing, visibility = {}, showFooter = false }) {
  const handleRowHeight = () => (isMobile ? "auto" : 52)
  return (
    <Box sx={{ width: "100%" }}>
      <DataGrid
        autoHeight
        hideFooter={!showFooter}
        disableColumnMenu
        disableRowSelectionOnClick
        rows={data}
        columns={columns}
        loading={loading}
        // initialState={{}}
        slots={{
          loadingOverlay: DataCircularLoader,
        }}
        columnVisibilityModel={{
          actions: isOwner,
          ...visibility,
        }}
        style={{
          "& .MuiDataGrid-root": {
            border: "none !important",
          },
        }}
        sx={{
          "&, [class^=MuiDataGrid]": { border: "none" },
          "& .MuiDataGrid-root": {
            border: "none !important",
          },
          "& .MuiDataGrid-cell": {
            overflow: "auto !important",
            whiteSpace: "initial !important",
          },
          "&>.MuiDataGrid-main": {
            ml: "-6px",
            mr: "-16px",
            "&>.MuiDataGrid-columnHeaders": {
              borderBottom: "none",
            },

            "& div div div div >.MuiDataGrid-cell": {
              borderBottom: "none",
            },
          },
        }}
        getRowHeight={ getRowHeight || handleRowHeight}
        getRowSpacing={getRowSpacing}
      />
    </Box>
  );
}

DataGridTable.defaultProps = {
  isMobile: false,
  loading: false,
  isOwner: false,
  columns: [],
  data: [],
};

DataGridTable.propTypes = {
  isMobile: PropTypes.bool,
  loading: PropTypes.bool,
  isOwner: PropTypes.bool,
  columns: PropTypes.arrayOf({}),
  data: PropTypes.arrayOf({}),
};

export default DataGridTable;
