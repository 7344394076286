import React, { useEffect, useMemo, useState} from 'react';
import Base from "../Base";
import {Stack} from "@mui/material";
import {WIDGET_IDENTITY} from "../config";
import Button from "@mui/material/Button";
import Label from "../components/Label";
import { ReactComponent as BinIcon} from '../../../assets/icons/waste-bin.svg'
import { ReactComponent as DonatIcon} from '../../../assets/icons/donat.svg'
import Chart from '../../explore/chart/Bar'
import useIsMobile from "../../../hooks/useIsMobile";
import {useModal} from "mui-modal-provider";
import SimpleDialog from "../../dialogs/SimpleDialog";
import SetMonthlyTargetForm from "./components/SetMonthlytargetForm";
import numberFormater from "../../../utils/formaters/numberFormater";
import {useChartLabels} from "../../../hooks/useChartLabels";
import {dataToChart} from "./helpers";
import {CHART_MODE} from "../../explore/chart/constants";
import {useSelector} from "react-redux";
import {selectExplore} from "../../../redux/explore";
import {formatChartValues, mepMetricParams} from "../../explore/helpers";
import Loader from "../Loader";
import {useNavigate} from "react-router-dom";
import {mainRoutes} from "../../../constants/urls";
import { defaultFoodImage } from "../../../constants/urls";
import {DATE_GRAN, LABELS, METRICS} from "../../../constants/explore";
import Grid from "@mui/material/Grid";
import dayjs from "dayjs";
import {getRecommendedTarget} from "../../../utils/widgets/getRecomendedTarget";
import {formatCoastValue} from "../../../utils/formaters/formatCoastValue";
import {useWidget} from "../useWidget";

const TRIMMINGS_DESCRIPTION = "Most Wasted Trimmings This Month"
const PREVENTABLE_DESCRIPTION = "Most Wasted Preventable This Month"
const EXCLUDE_FROM_LABEL = ['x', 'diff', 'total', 'target', 'mode']
const START_DATE = dayjs(new Date()).subtract(6, 'month').startOf('month')

const renderWasteItem = (description) => ({ name, image_url: imageUrl}) => (
  <Label
    title={name}
    description={description}
    Icon={imageUrl || defaultFoodImage}
  />
)

const MonthlySnapShot = (props) => {
  const isMobile = useIsMobile()
  const { showModal } = useModal()
  const { filters } = useSelector(selectExplore)
  const selectedSite = filters.sites[0]
  const params = useMemo(() => ({
    metric: mepMetricParams[filters.metric],
    ...(selectedSite ? { site: selectedSite.id} : {})
  }), [filters.metric, filters.sites[0]])

  const { data, isLoading, title, tip, updateCache, goToExplore} = useWidget(WIDGET_IDENTITY.MONTHLY_SNAPSHOT, {
    fetchKey: ['api/overview/monthly_snapshot/', params],
    exploreParams: {
      params: {
        ...filters,
        dateRange: [new Date(START_DATE), new Date()],
        labels: LABELS.nonFood,
        dateGranularity: DATE_GRAN.monthly,
      },
      path: `/${mainRoutes.explore}`,
    }
  })

  const {
    total_waste: totalWaste,
    trimmings_percent: trimmings,
    most_wasted_preventable: wastedPreventage = {},
    most_wasted_trimming: wastedTrimmings = {},
    chart,
    wasted_this_month: wastedThisMonth,
    unit,
    monthly_target: monthlyTarget,
  } = data || {}
  const totalThisMonth = numberFormater({
    value: wastedThisMonth || 0,
    fixed: 0,
    after: unit,
  })

  const [adviceTarget, setAdviceTarget] = useState()

  useEffect(() => {
    if(selectedSite) {
      getRecommendedTarget(selectedSite.id)
        .then(setAdviceTarget)
    }
  }, [selectedSite]);

  const formattedTarget = monthlyTarget && numberFormater({ value: monthlyTarget, fixed: 0, after: 'kg'})
  const targetThisMonth = monthlyTarget !== null && monthlyTarget >= 0 && `${dayjs(new Date()).format('MMM')} Target: ${formattedTarget}`

  const chartData = useMemo(() => chart ? dataToChart(chart) : [], [chart])
  const { activeLabels } = useChartLabels(chartData, EXCLUDE_FROM_LABEL)
  const showTargetModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      title: "Set Monthly Target Waste (KG)",
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <SetMonthlyTargetForm
        closeModal={() => {
        targetModal.hide()
        updateCache()
      }} unit={unit} recommendedTarget={adviceTarget} target={monthlyTarget} />,
    })
  }

  return (
    <Base title={title} tooltip={tip} value={targetThisMonth} id={WIDGET_IDENTITY.MONTHLY_SNAPSHOT} {...props}>
        <Base.Content style={{ minHeight: '280px' }}>
          {isLoading && <Loader />}
          {data && (
            <Grid container sx={{ minHeight: '280px' }}>
              <Grid item xs={12} md={4}>
                <Stack spacing={4}>
                  <Label
                    Icon={BinIcon}
                    title={formatCoastValue(totalThisMonth)}
                    description="Total Waste so far this month"
                  />
                  <Label
                    Icon={DonatIcon}
                    title={numberFormater({ value: trimmings, fixed: 0, after: '%' })}
                    description="Trimmings Percentage This Month"
                  />
                  { wastedPreventage && renderWasteItem(PREVENTABLE_DESCRIPTION)(wastedPreventage)}
                  { wastedTrimmings && renderWasteItem(TRIMMINGS_DESCRIPTION)(wastedTrimmings)}
                </Stack>
              </Grid>
              <Grid item xs={12} md={8}>
                <Chart
                  xKey={'x'}
                  direction={ isMobile ? 'vertical' : 'horizontal' }
                  data={chartData}
                  activeLabels={activeLabels}
                  unit={unit}
                  showLegends={false}
                  mode={CHART_MODE.snapshot}
                  duration={DATE_GRAN.monthly}
                  formatValues={formatChartValues}
                />
              </Grid>
            </Grid>
          )}
        </Base.Content>
      {!isMobile && (
        <Base.Footer>
          <Stack direction="row" justifyContent="space-between">
            <Button onClick={showTargetModal} disabled={isLoading} variant="link">Set Monthly Target (KG)</Button>
            <Button disabled={isLoading} variant="link" onClick={goToExplore}>Explore Monthly Snapshot</Button>
          </Stack>
        </Base.Footer>
      )}
    </Base>
  );
};

export default React.memo(MonthlySnapShot);
