import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {fetchProfile, selectProfile, updateProfile} from "./profile";
import {selectUser} from "./user";

const initialState = {
  pinned: []
}

export const unpinWidget = createAsyncThunk(
  'widgets/unpin',
  async (widgetId, { dispatch, getState}) => {
    const state = getState()
    const { data } = selectProfile(state)
    const widgets = data.focus_areas.filter(widget => widget !== widgetId)
    const payload = {
      focus_areas: widgets,
    }
    return dispatch(updateProfile({id: data.id, payload}))
  }
)

const widgetsSlice = createSlice({
  name: 'widgets',
  initialState,
  reducers: {
    pinWidget: function (state, action) {
      state.pinned.push(action.payload)
    },
    unPinWidget: function (state, action) {
      state.pinned = state.pinned.filter(widget => widget !== action.payload)
    }
  },
  extraReducers: builder => {
    builder
      .addCase(updateProfile.fulfilled, (state, { payload }) => {
        state.pinned = payload.focus_areas
    })
      .addCase(fetchProfile.fulfilled, (state, { payload }) => {
        state.pinned = payload.focus_areas
      })
  }
})

export const selectPinnedWidgets = state => state.widgets.pinned

export const { pinWidget, unPinWidget } = widgetsSlice.actions
export default widgetsSlice.reducer
