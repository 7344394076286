import { enqueueSnackbar } from "notistack";
import getDevice from "./getDevice";
import getEvents from "./getEvents";

const getData = async (
  device,
  completedTasks,
  setLoadingLabelled,
  setLoadingUnlabelled,
  setLabelledEvent,
  setUnlabelledEvent,
  setDevice,
  setShowAlert
) => {
  let params = {};
  if (device) {
    params = {
      serial: device.serial,
    };
  }

  const reset = () => {
    setLoadingLabelled(false);
    setLoadingUnlabelled(false);
  };

  try {
    const response = await getEvents(params);
    const { status, data } = response;
    if (status === 204) {
      setShowAlert(true);
    } else {
      if (!device || device.serial !== data.unlabelled.device_serial) {
        setDevice(null);
        await getDevice(data.device_id, setDevice);

        if (device && completedTasks === device.total_events) {
          enqueueSnackbar("All events of this device have been processed.", {
            variant: "info",
            autoHideDuration: 6000,
          });
        }
      }
      if (data.labelled) {
        setLabelledEvent(data.labelled);
      }

      setUnlabelledEvent(data.unlabelled);
    }
  } catch (err) {
    enqueueSnackbar(err.message, { variant: "error" });
  } finally {
    reset();
  }
};

export default getData;
