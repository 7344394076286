import { formatDataQuery } from "../../../utils/date/formatDataQuery";
import { getOrdering } from "./getOrdering";
import identifiedHistory from "../utils/identifiedHistory";
import unidentifiedHistory from "../utils/unidentifiedHistory";
import reviewHistory from "../utils/reviewHistory";
import { baseAxios } from "../../../utils/axios";

const fetchReviewEvents = async ({ params }) => {
  try {
    const response = await baseAxios.get("api/events/review/", { params });
    return response.data;
  } catch (error) {
    console.error(error);
    return {};
  }
};

const getReviewEvents = async ({
  offset,
  limit,
  site,
  zone,
  food,
  device,
  statusType,
  autoLabelType,
  date,
  isSecondary,
  location,
  sortModel,
  setRowCountState,
  history,
}) => {
  const eventParams = {
    limit,
    offset,
  };
  if (statusType && statusType.length) {
    eventParams.status = statusType;
  }
  if (autoLabelType) {
    eventParams.auto_label = autoLabelType === "Yes";
  }
  if (site && site.length) eventParams.site = site.map((el) => el.id);
  if (zone && zone.length) eventParams.zone = zone.map((el) => el.name);
  if (food) eventParams.food = food.id;
  if (date && date.length) {
    eventParams.startDate = formatDataQuery(date[0]);
    eventParams.endDate = formatDataQuery(date[1]);
  }
  if (isSecondary !== null) eventParams.is_secondary = isSecondary;

  const ordering = getOrdering(sortModel);
  if (ordering) eventParams.ordering = ordering;

  const newEvents = await fetchReviewEvents({ params: eventParams });

  if (history) {
    if (isSecondary === false) {
      identifiedHistory({ location, eventParams, site, food, device, history });
    } else if (isSecondary === true) {
      unidentifiedHistory({ location, eventParams, site, zone, history });
    } else if (isSecondary === null) {
      reviewHistory({
        location,
        eventParams,
        statusType,
        site,
        food,
        device,
        autoLabelType,
        history,
      });
    }
  }

  if (newEvents) {
    setRowCountState(newEvents.count);
    return newEvents.results;
  }

  return [];
};

export default getReviewEvents;
