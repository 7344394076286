export const appConfig = {
  isProduction: process.env.NODE_ENV === "production",
  apiURL: process.env.REACT_APP_API,
  chatwootToken: process.env.REACT_APP_CHATWOOT_TOKEN,
  storageSasUrl: process.env.REACT_APP_STORAGE_SAS_URL,
  storageContaineName: process.env.REACT_APP_BLOB_CONTAINER,
  mapsApiKey: process.env.REACT_APP_MAPS_API_KEY,
  mapsApiId: process.env.REACT_APP_MAPS_API_ID,
  MIXPANEL_TOKEN: process.env.REACT_APP_MIXPANEL_TOKEN,
  REACT_APP_SENTRY_DSN: process.env.REACT_APP_SENTRY_DSN,
  googleClientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  weglotApiKey: process.env.REACT_APP_WEGLOT_API_KEY,
};
