import React, {useEffect, useMemo, useState} from "react";
import {
  Autocomplete,
  Box,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import BlueButton from "../components/Buttons/BlueButton";
import { CustomCheckbox } from "../styled/GlobalStyled";
import { adminEventStatusList } from "../../constants/eventStatusList";
import { titleCase } from "../../utils/formaters/titleCase";
import { fetchFoods, selectFoods } from "../../redux/foods";
import { selectUser } from "../../redux/user";
import { fetchFeed, updateEvent } from "../../redux/reducers/feed";
import axiosInstance from "../../utils/axios";
import {useLang} from "../../hooks/useLang";
import {translate} from "../../utils/lang";

function EditEventForm({ site, id, updateParams, onCancel, params }) {

  const { status, food, trimmings } = updateParams;

  const dispatch = useDispatch();

  const { foods } = useSelector(selectFoods);
  const user = useSelector(selectUser);
  const { getLocale, lang } = useLang()

  const [currentStatus, setCurrentStatus] = React.useState(status);
  const [currentFood, setCurrentFood] = React.useState(null);
  const [currentTrimming, setCurrentTrimming] = React.useState(trimmings);
  const [translatedFood, setTranslatedFood] = useState([])

  const handleStatus = (event) => setCurrentStatus(event.target.value);
  const handleFood = (_event, newValue) => setCurrentFood(newValue);
  const handleTrimmings = () => setCurrentTrimming((prev) => !prev);

  useEffect(() => {
    if (foods.length) {
      const namesToTranslate = foods.map(food => food.name)
      translate(namesToTranslate, lang)
        .finally(() => {
          const selectedFood = foods.find((el) => el.id === food);
          if (selectedFood) {
            setCurrentFood({...selectedFood, name: getLocale(selectedFood.name)});
          }
          setTranslatedFood(foods.map(food => ({...food, name: getLocale(food.name)})))
        })
    }
  }, [foods]);

  useEffect(() => {
    dispatch(fetchFoods({ fields: ["id", "name"], site: site }));
  }, []);

  const handleConfirm = async () => {
    if (currentStatus === "DISCARDED") {
      await axiosInstance.patch(`api/events/${id}/`, {
        user: user.id,
        status: "DISCARDED",
      });
      dispatch(fetchFeed({ params }));
    } else {
      const updateFeedParams = {
        ...updateParams,
        status: currentStatus,
        food: currentFood.id,
        trimmings: currentTrimming,
        user: user.id,
      };

      const { status, data } = await axiosInstance.patch(
        `api/events/${id}/`,
        updateFeedParams
      );
      if (status === 200) {
        dispatch(updateEvent({ ...data }));
      }
    }
    onCancel();
  };

  return (
    <>
      <DialogContent>
        <FormControl variant="standard" fullWidth>
          <InputLabel id="status-select-standard-label">Status</InputLabel>
          <Select
            labelId="status-select-standard-label"
            id="status-select-standard"
            value={currentStatus}
            onChange={handleStatus}
          >
            {adminEventStatusList.map((el) => (
              <MenuItem key={el} value={el}>
                {titleCase(el)}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Autocomplete
          value={currentFood}
          options={translatedFood}
          getOptionLabel={(option) => option.name}
          id="food-select-standard"
          onChange={handleFood}
          renderInput={(params) => (
            <TextField {...params} label="Food" variant="standard" fullWidth />
          )}
          sx={{ m: "1rem 0" }}
        />
        <Box sx={{ m: "1.5rem 0" }}>
          <FormControlLabel
            control={
              <CustomCheckbox
                sx={{ padding: "0 11px" }}
                checked={currentTrimming}
                onChange={handleTrimmings}
                name="trimmings"
              />
            }
            label="Trimmings"
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ mb: 4, mr: 4, mt: -4 }}>
        <BlueButton name="Cancel" onClick={onCancel} />
        <BlueButton name="Confirm" onClick={handleConfirm} />
      </DialogActions>
    </>
  );
}

EditEventForm.defaultProps = {};

EditEventForm.propTypes = {};

export default EditEventForm;
