import Cropper from "cropperjs";
import PropTypes from "prop-types";

import "cropperjs/dist/cropper.css";

function CropperImage({ src, selector, boundingArea, setImageUrl }) {
  const [x, y, width, height] = boundingArea;

  if (src) {
    const image = document.querySelector(selector);
    image.src = src;
    const cropper = new Cropper(image, {
      ready: () => {
        cropper.setData({
          x,
          y,
          width,
          height,
          rotate: 0,
          scalex: 1,
          scaley: 1,
        });

        cropper.getCroppedCanvas().toBlob((blob) => {
          // `blob` is the image in Blob format
          // You can upload this blob directly to a server or create a URL
          const url = URL.createObjectURL(blob);
          setImageUrl(url);  // Display the image using this URL
          cropper.destroy();
        }, 'image/png', 0.7);
      },
    });
    return cropper;
  }
  setImageUrl(null);
}

CropperImage.propTypes = {
  src: PropTypes.string,
  selector: PropTypes.string.isRequired,
  boundingArea: PropTypes.arrayOf(PropTypes.number),
  setImageUrl: PropTypes.func.isRequired,
};

CropperImage.defaultProps = {
  src: null,
  boundingArea: [0, 0, 1920, 1080],
};

export default CropperImage;
