export const SCALES_ONLY = "Scales Only";
export const CAMERA_AND_SCALES = "Camera and Scales";

export const typeChoicesDict = {
  SCALES_ONLY,
  CAMERA_AND_SCALES,
};

const getTypeChoices = (status) => typeChoicesDict[status] || null;

export default getTypeChoices;
