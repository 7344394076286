import PropTypes from "prop-types";

export const numberFormater = ({ value, fixed, before, after }) => {
  const formattedValue = parseFloat(value).toFixed(fixed);
  const localeValue = Number(formattedValue).toLocaleString("en-GB");
  return String(before || "") + localeValue + String(after || "");
};

numberFormater.defaultProps = {
  before: "",
  after: "",
  fixed: 0,
  value: 0,
};

numberFormater.propTypes = {
  before: PropTypes.string,
  after: PropTypes.string,
  fixed: PropTypes.number,
  value: PropTypes.number,
};

export default numberFormater;
