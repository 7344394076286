import React from "react";
import { Box, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import discardEvent from "./axios/discardEvent";
import incorrectEvent from "./axios/incorrectEvent";
import correctEvent from "./axios/correctEvent";
import { selectUser } from "../../redux/user";
import useKeyPress from "../../hooks/useKeyPress";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";

const Wrapper = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "5px 0",
}));

const Buttons = styled(Box)(() => ({
  display: "flex",
}));

const WasteButton = styled(Button)(() => ({
  width: "240px",
  height: "50px",
  margin: "5px",
}));

function ButtonSection({
  event,
  reset,
  setLeftTime,
  loadingEvent,
  setLoadingEvent,
}) {
  const user = useSelector(selectUser);
  const mixpanelTrack = useMixpanelTrack();

  const handleDiscard = async () => {
    if (event) {
      setLoadingEvent(true);
      await discardEvent({
        eventId: event.id,
        user,
        setLeftTime,
        reset,
      });
    }
    mixpanelTrack("Verification - Discard - Click");
  };
  const handleIncorrect = async () => {
    if (event) {
      setLoadingEvent(true);
      await incorrectEvent({
        eventId: event.id,
        user,
        setLeftTime,
        reset,
      });
    }
    mixpanelTrack("Verification - Incorrect - Click");
  };
  const handleCorrect = async () => {
    if (event) {
      setLoadingEvent(true);
      await correctEvent({
        eventId: event.id,
        user,
        setLeftTime,
        reset,
      });
    }
    mixpanelTrack("Verification - Correct - Click");
  };

  useKeyPress(["1"], handleDiscard);
  useKeyPress(["2"], handleIncorrect);
  useKeyPress(["3"], handleCorrect);

  return (
    <Wrapper>
      <Box sx={{ width: "51%" }} />
      <Buttons sx={{ width: "49%" }}>
        <WasteButton
          data-testid="discard-btn"
          disabled={loadingEvent}
          color="error"
          variant="contained"
          onClick={handleDiscard}
        >
          Discard
        </WasteButton>
        <WasteButton
          data-testid="incorrect-btn"
          disabled={loadingEvent}
          color="warning"
          variant="contained"
          onClick={handleIncorrect}
        >
          Incorrect
        </WasteButton>
        <WasteButton
          data-testid="correct-btn"
          disabled={loadingEvent}
          color="success"
          variant="contained"
          onClick={handleCorrect}
        >
          Correct
        </WasteButton>
      </Buttons>
    </Wrapper>
  );
}

ButtonSection.propTypes = {
  event: PropTypes.shape({
    id: PropTypes.number.isRequired,
    status: PropTypes.string.isRequired,
  }),
  reset: PropTypes.func.isRequired,
  setLeftTime: PropTypes.func.isRequired,
  loadingEvent: PropTypes.bool.isRequired,
  setLoadingEvent: PropTypes.func.isRequired,
};

ButtonSection.defaultProps = {
  event: null,
};

export default ButtonSection;
