import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { startDateValue } from "../utils/date/startDateValue";
import { endDateValue } from "../utils/date/endDateValue";
import { formatDataQuery } from "../utils/date/formatDataQuery";
import axiosInstance from "../utils/axios";
import constants from "../components/filter/configs/constants";
import formatInitialListState from "../utils/formaters/formatInitialListState";

// zones and devices
export const fetchDevices = createAsyncThunk(
  "filters/fetchDevices",
  async () => {
    const params = { fields: ["id", "name"] };
    const response = await axiosInstance.get("api/devices/", { params });
    return response.data.sort((a, b) => a.name - b.name);
  }
);

export const fetchSites = createAsyncThunk("filters/fetchSites", async () => {
  const params = { fields: ["id", "name"] };
  const response = await axiosInstance.get("api/sites/", { params });
  return response.data.sort((a, b) => a.name - b.name);
});

export const fetchFoods = createAsyncThunk("filters/fetchSites", async () => {
  const params = { fields: ["id", "name"] };
  const response = await axiosInstance.get("api/sites/", { params });
  return response.data.sort((a, b) => a.name - b.name);
});

const initialState = () => {
  const urlParams = new URLSearchParams(window.location.search);

  const condense = urlParams.get("condense");
  const withSecondaryWaste = urlParams.get("withSecondaryWaste");
  const trimmings = urlParams.get("trimmings");
  const secondaryWaste = urlParams.get("secondaryWaste");
  const nonFood = urlParams.get("nonFood");
  const orderingParam = urlParams.get("ordering");
  const display = urlParams.get("display");
  const siteList = formatInitialListState({
    ids: urlParams.getAll("siteId"),
    names: urlParams.getAll("siteName"),
  });
  const zoneList = formatInitialListState({
    ids: urlParams.getAll("zoneId"),
    names: urlParams.getAll("zoneName"),
  });
  const foodList = formatInitialListState({
    ids: urlParams.getAll("foodId"),
    names: urlParams.getAll("foodName"),
  });
  const groupList = formatInitialListState({
    ids: urlParams.getAll("groupId"),
    names: urlParams.getAll("groupName"),
  });

  const { default: orderDefault, items: orderItems } = constants.ordering;
  const orderIndex = orderItems.findIndex((el) => el.id === orderingParam);

  const { default: displayDefault, items: displayItems } = constants.display;
  const displayIndex = displayItems.findIndex((el) => el.id === display);

  return {
    view: {
      display: displayItems[displayIndex] || displayDefault,
      condense: condense && condense === "true",
      ordering: orderItems[orderIndex] || orderDefault,
      withSecondaryWaste: withSecondaryWaste && withSecondaryWaste === "true",
    },
    filters: {
      startDate: formatDataQuery(startDateValue()),
      endDate: formatDataQuery(endDateValue()),
      trimmings: trimmings === null ? null : trimmings === "true",
      secondaryWaste: secondaryWaste && secondaryWaste === "true",
      nonFood: nonFood && nonFood === "true",
      site: siteList,
      zone: zoneList,
      food: foodList,
      group: groupList,
      role: [],
      autoLabel: constants.autoLabel.default,
      status: constants.status.default,
      ordering: orderItems[orderIndex] || orderDefault,
    },
  };
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState: initialState(),
  reducers: {
    updateFilters(state, { payload }) {
      state.filters = {
        ...state.filters,
        ...payload,
        startDate: formatDataQuery(payload.startDate),
        endDate: formatDataQuery(payload.endDate),
      };
    },
    updateDisplay(state, { payload }) {
      state.view = {
        ...state.view,
        ...payload,
      };
    },
  },
});

export const { updateFilters, updateDisplay } = filtersSlice.actions;

export const selectFilters = (state) => state.filters;

export default filtersSlice.reducer;
