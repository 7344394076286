import {COLORS} from "../../../constants/base";
import {METRICS} from "../../../constants/explore";
import numberFormater from "../../../utils/formaters/numberFormater";
import dayjs from "dayjs";

export const BAR_TYPE = {
  stack: 'stack',
  diff: 'diff',
}
const isCurrentMonth = date => {
  try {
    return dayjs(date).month() === dayjs(new Date()).month()
  }
  catch (e) {
    return false
  }
}
const TARGET_LABEL = 'Monthly Target'
export const dataToChart = data => data.map(({y, monthly_target: monthlyTarget, ...item}) => ({
  ...item,
  'Waste': y,
  [TARGET_LABEL]: isCurrentMonth(item.x) ? monthlyTarget : null,
  total: y,
  target: isCurrentMonth(item.x) ? monthlyTarget : null,
}))

export const targetBarColor = item => {
  if(item.dataKey === TARGET_LABEL) {
    return item.payload.total > item.payload.target ? COLORS.red : COLORS.gray
  }
}

const mapTargetByMetric = {
  [METRICS.totalWaste]: 'kg',
  [METRICS.coastWaste]: 'value',
  [METRICS.wasteEmission]: 'co2',
}

export const getTargetByMetric = (metric) => targets => {
  if(!targets) return
  return targets[mapTargetByMetric[metric]]
}

