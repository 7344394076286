import React from "react";
import * as Yup from "yup";
import PropTypes from "prop-types";
import { Formik } from "formik";
import { useSelector } from "react-redux";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import {
  Button,
  DialogContent,
  FormControl,
  InputAdornment,
  TextField,
} from "@mui/material";
import { useSnackbar } from "notistack";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import { Alert } from "../styled/GlobalStyled";
import { selectUser } from "../../redux/user";
import { baseAxios } from "../../utils/axios";
import { COLORS } from "../../constants/base";
import {useLang} from "../../hooks/useLang";
import 'dayjs/locale/de'

dayjs.extend(utc);

function PriceForm({ food, setFoodList, handleCloseModel, mixpanelSubmit }) {
  const user = useSelector(selectUser);
  const { lang } = useLang()
  const { enqueueSnackbar } = useSnackbar();

  return (
    <DialogContent>
      <Formik
        initialValues={{
          food: food.name,
          foodId: food.id,
          price: food.price_per_kg,
          date: dayjs(food.price_date || new Date()),
        }}
        isInitialValid
        validateOnChange={false}
        validateOnMount={false}
        validationSchema={Yup.object().shape({
          price: Yup.number().required("Price per KG is required"),
          food: Yup.string().required("Food is required"),
          foodId: Yup.number().required("Food is required"),
          date: Yup.date().required("Date is required"),
        })}
        onSubmit={async (values, { setErrors, setSubmitting }) => {
          const data = {
            food: values.foodId,
            value: values.price,
            user: user.id,
            date: values.date.format("YYYY-MM-DD"),
          };
          try {
            const responce = await baseAxios.post("api/food-costs/", data);

            setFoodList((prevValue) =>
              prevValue.map((f) =>
                f.id === food.id
                  ? { ...f, price_per_kg: parseFloat(responce.data.value) }
                  : f
              )
            );
          } catch (err) {
            enqueueSnackbar("Oops, something went wrong.", {
              variant: "error",
            });
            setErrors(err.responce ? err.responce.data : err.message);
          }
          setSubmitting(true);
          handleCloseModel();
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          setFieldValue,
          isSubmitting,
          values,
        }) => (
          <form noValidate onSubmit={handleSubmit}>
            {errors.submit && (
              <Alert mt={2} mb={3} severity="warning">
                {errors.submit}
              </Alert>
            )}
            <TextField
              fullWidth
              disabled
              variant="standard"
              type="text"
              name="food"
              label="Food"
              value={values.food}
              error={Boolean(errors.food)}
              helperText={errors.food}
              // onBlur={handleBlur}
              // onChange={handleChange}
              sx={{
                "& .MuiInputBase-input.Mui-disabled": {
                  WebkitTextFillColor: COLORS.tableBlack,
                },
              }}
              my={4}
            />

            <TextField
              fullWidth
              variant="standard"
              type="text"
              name="price"
              label="Custom Price (Per KG)"
              value={values.price}
              error={Boolean(errors.price)}
              helperText={errors.price}
              onBlur={handleBlur}
              onChange={handleChange}
              my={4}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />

            <FormControl
              fullWidth
              variant="standard"
              sx={{ marginBottom: "1rem" }}
            >
              <LocalizationProvider
                dateAdapter={AdapterDayjs}
                adapterLocale={lang}
              >
                <DatePicker
                  sx={{
                    "& .MuiIconButton-root": {
                      marginRight: 0,
                    },
                  }}
                  label="Date of price"
                  maxDate={dayjs(new Date().toUTCString())}
                  value={values.date}
                  onChange={(day) => setFieldValue("date", dayjs(day))}
                  slotProps={{ textField: { variant: "standard" } }}
                  components={{ OpenPickerIcon: CalendarTodayIcon }}
                />
              </LocalizationProvider>
            </FormControl>

            <Button
              sx={{ height: "50px" }}
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
              onClick={mixpanelSubmit}
            >
              Update Food
            </Button>
          </form>
        )}
      </Formik>
    </DialogContent>
  );
}

PriceForm.propTypes = {
  food: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    price_per_kg: PropTypes.number.isRequired,
    price_date: PropTypes.string,
  }),
  setFoodList: PropTypes.func.isRequired,
  handleCloseModel: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
};

PriceForm.defaultProps = {
  food: null,
};

export default PriceForm;
