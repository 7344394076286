import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import dayjs from "dayjs";
import { styled } from "@mui/material/styles";
import DateRangePicker from "rsuite/DateRangePicker";
import CustomProvider from "rsuite/CustomProvider";
import { deDE, enGB } from 'rsuite/locales'
import PropTypes from "prop-types";
import { Box, FormControl, InputLabel, useMediaQuery } from "@mui/material";
import { ClickAwayListener } from "@mui/base";
import GlobalStyles from "@mui/material/GlobalStyles";

import predefinedRanges from "./config/predefinedRanges";

import "rsuite/dist/rsuite-no-reset.min.css";
import {COLORS} from "../../constants/base";
import { useLang } from "../../hooks/useLang";

const LOCALES_MAP = {
  'en': enGB,
  'de': deDE,
}

const defaultStyle = theme => ({
  marginTop: "8px",
  borderBottom: "1px solid #d3d8dd",
  "&.rs-picker-has-value .rs-picker-toggle-value": {
    color: theme.palette.color.tableBlack,
  },
  "& svg": {
    color: theme.palette.color.grayIcon,
    fontSize: "16px",
    margin: "2px -2px",
  },
  "&.rs-picker:hover": {
    backgroundColor: "transparent",
    borderBottom: "1px solid #d3d8dd",
  },
  "& .rs-picker-toggle": {
    boxShadow: "none",
  },
})

const outLineStyle = theme =>  ({
  border: `1px solid ${COLORS.inputBorder}`,
  '&:hover': {
    borderColor: `${COLORS.inputBorderHover} !important`
  },
  borderRadius: '16px',
  "&.rs-picker-has-value .rs-picker-toggle-value": {
    color: theme.palette.color.tableBlack,
  },
  "& svg": {
    color: theme.palette.color.grayIcon,
    fontSize: "16px",
    margin: "2px -2px",
    top: '14px !important'
  },
  "& .rs-picker-toggle": {
    boxShadow: "none",
    background: 'none',
    borderRadius: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  },
  '& .rs-picker-toggle-clean': {
    top: '16px !important'
  },
  '& .rs-input-group-inside': {
    borderRadius: '16px'
  },
  '& .rs-input-group-inside:hover': {
    borderColor: 'transparent',
  },
  '& .rs-date-range-input': {
    borderRadius: '16px',
    paddingTop: '16px',
    paddingBottom: '16px',
  }
})

const StyleDateRangePicker = styled(DateRangePicker)(({ theme, outlined }) => ({
  "&.react-datepicker-popper": {
    zIndex: 1111,
  },
  cursor: 'pointer',
  ...(outlined ? outLineStyle(theme) : defaultStyle(theme)),
  "& .rs-picker-toggle:hover": {
    backgroundColor: "transparent",
  },
  "& .rs-picker-toggle-textbox": {
    backgroundColor: theme.palette.color.whiteSElected,
    paddingLeft: "12px",
    color: theme.palette.color.tableBlack,
  },
  "& .rs-picker-toggle-active": {
    backgroundColor: "transparent",
  },
  "& .rs-picker-daterange-panel": {
    "& .rs-picker-daterange-predefined .rs-btn": {
      color: "red",
    },
  },
}));

function DateRangeInput({ value, setValue, fullWidth, variant, label, onClean, editable, availableDate, ranges }) {
  const ref = useRef(null);
  const { lang, getLocale } = useLang();

  const match = useMediaQuery("(max-width: 600px)");

  const [open, setOpen] = useState(false);
  const [dates, setDates] = useState([]);

  const { afterToday } = DateRangePicker;

  const selectHandler = useCallback(
    (date) => {
      const compareFn = (a, b) => new Date(a) - new Date(b);

      if (date === null) {
        setDates([new Date(dayjs().date(1)), new Date()]);
      } else if (Array.isArray(date)) {
        setDates(date);
      } else {
        setDates((prev) => [...prev, date].sort(compareFn));
      }
    },
    [setDates]
  );

  useEffect(() => {
    if (dates.length === 2) {
      setValue(dates);
      setOpen(false);
      setDates([]);
    }
  }, [dates]);

  const isTop = useMemo(() => {
    if (ref.current !== null) {
      const { top } = ref.current?.getBoundingClientRect();
      return top < 472;
    } else {
      return false;
    }
  }, [ref.current, open]);

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const openHandler = () => setOpen(true);

  const closeHandler = () => {
    setOpen(false);
  };

  useEffect(() => closeHandler(), [isMobile]);
  const handleClean = () => {
    if(onClean) {
      onClean()
      setDates([])
    }
  }

  return (
    <>
      <GlobalStyles
        styles={{
          ".rs-picker-menu": {
            zIndex: 1301,
            ...((isMobile && isTop && !variant) && { top: "2rem !important" }),
          },
          ".rs-picker-toolbar > .rs-stack-item:last-child": {
            display: "none",
          },
          ".rs-picker-menu .rs-stack-item .rs-picker-toolbar-ranges": {
            maxWidth: "468px",
          },
          ".rs-picker-daterange-menu .rs-picker-daterange-panel-show-one-calendar \
            .rs-stack-item .rs-picker-toolbar-ranges": {
            width: "inherit",
          },
          '.rs-picker-popup-daterange': {
            zIndex: 1302,
          },
          '.rs-picker-popup .rs-picker-toolbar-ranges': {
            maxWidth: '450px',
          }
        }}
      />
      <ClickAwayListener
        disableReactTree={false}
        onClickAway={closeHandler}
        open={open}
      >
        <FormControl fullWidth={fullWidth} ref={ref} variant={variant || 'standard'}>
          {label && <InputLabel>{label}</InputLabel>}
          <CustomProvider locale={LOCALES_MAP[lang]}>
            <Box
              component={StyleDateRangePicker}
              onClick={openHandler}
              onClosed={closeHandler}
              open={open}
              match={match ? 1 : 0}
              showOneCalendar={match}
              ranges={ ranges || predefinedRanges}
              appearance="subtle"
              value={value}
              onSelect={selectHandler}
              onChange={selectHandler}
              format="dd-MM-yyyy"
              character={lang === 'de' ? ' bis ' : ' to ' }
              placement="auto"
              placeholder={getLocale("Select Date")}
              shouldDisableDate={availableDate || afterToday()}
              outlined={variant === 'outlined'}
              onClean={handleClean}
              editable={editable}
              id='range-picker'
            />
          </CustomProvider>
        </FormControl>
      </ClickAwayListener>
    </>
  );
}

DateRangeInput.defaultProps = {
  fullWidth: true,
};

DateRangeInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
  setValue: PropTypes.func.isRequired,
  fullWidth: PropTypes.bool,
};

export default DateRangeInput;
