import * as Yup from "yup";
import {isPhoneValid} from "../../utils/validation/isPhoneValid";

//
// type ChoiceAnswer = Exclude<AnswerType, AnswerType.Text | AnswerType.Number>
// type SingleAnswer = Exclude<AnswerType, ChoiceAnswer>
//
// interface WithAnswer {
//   type: ChoiceAnswer;
//   answers: string[];
// }
//
// interface WithoutAnswer {
//   type: SingleAnswer;
// }
//
// export type Question = {
//   field: string;
//   question: string;
// } & (WithoutAnswer | WithAnswer);

export const AnswerType = {
  Text: 'text',
  Number: 'number',
  Radio: 'radio',
  Dropdown: 'dropdown'
}
export const CUSTOM_ANSWER = 'customAnswer'
export const OTHER_ANSWER = "Others (please specify)"
const LENGTH_ERROR_MESSAGE = 'Answer must be at most 155 characters'


export const ONBOARDING_QUESTIONS = [
  {
    field: 'first_name',
    question: 'First Name:',
    type: AnswerType.Text,
    validation: Yup.string().max(155, LENGTH_ERROR_MESSAGE),
  },
  {
    field: 'surname',
    question: 'Last Name:',
    type: AnswerType.Text,
    validation: Yup.string().max(155, LENGTH_ERROR_MESSAGE),
  },
  {
    field: 'job_title',
    question: 'Role in the Organisation:',
    type: AnswerType.Dropdown,
    answers: [
      'Head Chef',
      'Sous or Junior Chef',
      'Manager',
      'Sustainability Manager',
      'Owner/Corporate Manager',
    ],
  },
  {
    field: 'phone_number',
    question: 'Contact Number:',
    type: AnswerType.Number,
    validation: Yup.string().test('is-phone-valid', 'Phone number is invalid', isPhoneValid).required("Phone number is required"),
  },
  {
    field: 'reduce_waste_reason',
    question: 'What is the main reason you want to reduce your food waste?',
    type: AnswerType.Dropdown,
    answers: [
      'Environmental',
      'Financial Savings',
      'Operational Efficiency',
    ],
  },
  {
    field: 'waste_management_level',
    question: 'How well do you think food waste is managed in your organisation currently?',
    type: AnswerType.Radio,
    answers: [
      {
        value: "Outstanding",
        label: '🌟 Outstanding - We excel in reducing waste have implemented sustainability practices.',
      },
      {
        value: "Good",
        label: '👍 Good - We actively monitor and manage our food waste.',
      },
      {
        value: 'Fair',
        label: '➖ Fair - Our efforts are inconsistent and often depend on operational capacity.',
      },
      {
        value: 'Needs Improvement',
        label: '👎 Needs Improvement - Limited actions are taken towards waste management due to constraints.',
      },
      {
        value: 'Critical',
        label: '⚠ Critical - Struggling significantly with waste control and overproduction.',
      },
    ],
  },
  {
    field: 'most_common_waste',
    question: 'What type of food waste do you think is most commonly occurring in your organisation?',
    type: AnswerType.Dropdown,
    answers: [
      'Preparation Waste',
      'Production Waste',
      'Inventory/Storage Spoilage',
      'Others (please specify)',
    ],
    customAnswerOn: 'Others (please specify)',
    validation: Yup.string().max(155),
  },
  {
    field: 'sustainable_waste_management_familiarity',
    question: 'How familiar are you with sustainable waste management practices?',
    type: AnswerType.Dropdown,
    answers: [
      'Very familiar',
      'Somewhat familiar',
      'Not very familiar',
      'Not familiar at all',
    ],
  },
]
