import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemIcon,
  ListItemText,
  MenuItem,
  CircularProgress as MuiCircularProgress,
  Select,
} from "@mui/material";
import styled from "@emotion/styled";

import { CustomCheckbox } from "../styled/GlobalStyled";
import { titleCase } from "../../utils/formaters/titleCase";

const CircularProgress = styled(MuiCircularProgress)(({ theme }) => ({
  color: theme.palette.color.accentBlue,
}));

const StyledSelect = styled(Select)(() => ({
  fontSize: "15px",
}));

function ReviewSelectStandard({
  open,
  setOpen,
  value,
  setValue,
  items,
  multiple,
  label,
  styleProp,
  fullWidth,
  nameFormater,
  emptyLabel,
  emptyValue,
  isTitle,
  loading,
  variant,
  placeholder,
}) {
  const [isOpen, setIsOpen] = useState(Boolean(open));

  const closeSelect = () => {
    setIsOpen(false);
  };

  const openSelect = () => {
    setIsOpen(true);
  };

  useEffect(() => {
    setIsOpen(open);
  }, [open]);

  useEffect(() => {
    if (setOpen) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  const handleChange = ({ target }) => {
    const { value: targetValue } = target;

    if (Array.isArray(targetValue)) {
      if (targetValue.includes("all")) {
        if (targetValue.length < 2) {
          setValue(items);
        } else {
          setValue([]);
        }
      } else {
        setValue(targetValue);
      }
    } else {
      setValue(targetValue);
    }
  };

  const labelValue = () => {
    if (!value) return null;

    const plus = value.length > 1 ? ` +${value.length - 1}` : "";

    if (typeof value[0] === "string") {
      const text = nameFormater ? value.map(nameFormater) : value
      const result = isTitle ? titleCase(value[0]) : text;

      return multiple ? result + plus : result;
    }

    const item = items.find((el) => {
      if (Array.isArray(value)) {
        return el.id === value[0].id;
      }

      return el.id === value.id;
    });

    if (nameFormater && item) {
      return isTitle
        ? titleCase(nameFormater(item)) + plus
        : nameFormater(item) + plus;
    }

    if (item) return isTitle ? titleCase(item.name) + plus : item.name + plus;
    return "";
  };

  const isChecked = (status) => {
    if (typeof status === "string") {
      return value.indexOf(status) > -1;
    }

    if (Array.isArray(value)) {
      return value.some((el) => {
        if (el) return el.id === status.id;

        return false;
      });
    }
    if (typeof value === "object" && value !== null) {
      return value.id === status.id;
    }

    return false;
  };

  const isPrimaryItem = ({ isTitleCase, isFormater, status }) => {
    const isString = typeof status === "string";

    if (isFormater) {
      return isTitleCase
        ? titleCase(nameFormater(status))
        : nameFormater(status);
    }
    if (isString) return isTitleCase ? titleCase(status) : status;

    return isTitleCase ? titleCase(status.name) : status.name;
  };

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: "350px",
      },
    },
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "left",
    },
  };

  const styles = useMemo(() => {
    if (styleProp) return styleProp;
    return !fullWidth ? { minWidth: "120px", maxWidth: "160px" } : {};
  }, [styleProp, fullWidth]);

  const setMenuItem = (status) => {
    if (value.length && typeof status !== "string") {
      const target = value.find((el) => el.id === status.id);
      if (target) {
        return target;
      }
    }
    return status;
  };

  return (
    <FormControl style={styles} variant={variant ?? 'standard'} fullWidth={fullWidth}>
      {label && <InputLabel>{label}</InputLabel>}
      <StyledSelect
        open={isOpen}
        onOpen={openSelect}
        onClose={closeSelect}
        multiple={multiple}
        id={`select-${label}`}
        value={value || []}
        renderValue={() => labelValue() || emptyLabel}
        onChange={handleChange}
        MenuProps={MenuProps}
        sx={{ marginBottom: 0 }}
        label={ !value?.length && placeholder}
      >
        {loading && (
          <MenuItem value="all">
            <ListItemIcon>
              <CircularProgress size="1rem" />
            </ListItemIcon>
            <ListItemText primary="Loading..." />
          </MenuItem>
        )}
        {!loading && multiple && (
          <MenuItem
            value="all"
            classes={{
              root:
                items.length > 0 && value.length === items.length
                  ? "Mui-selected"
                  : "",
            }}
          >
            <ListItemIcon>
              <Checkbox
                sx={{ padding: "5px" }}
                checked={value.length === items.length}
                indeterminate={value.length > 0 && value.length < items.length}
              />
            </ListItemIcon>
            <ListItemText primary="All" />
          </MenuItem>
        )}
        {!loading && emptyValue && (
          <MenuItem value="">
            <ListItemText primary={emptyValue} />
          </MenuItem>
        )}
        {!loading &&
          items.map((status) => (
            <MenuItem
              id={`item-${label}-${status.id}`}
              key={typeof status === "string" ? status : status.id}
              value={setMenuItem(status)}
            >
              {multiple && (
                <ListItemIcon sx={{ padding: "7px" }}>
                  <CustomCheckbox checked={isChecked(status)} />
                </ListItemIcon>
              )}
              <ListItemText
                primary={isPrimaryItem({
                  isTitleCase: isTitle,
                  isFormater: nameFormater,
                  status,
                })}
              />
            </MenuItem>
          ))}
      </StyledSelect>
    </FormControl>
  );
}

const valuePropType = PropTypes.shape({
  id: PropTypes.string,
  name: PropTypes.string,
});

ReviewSelectStandard.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
  value: PropTypes.oneOfType([valuePropType, PropTypes.arrayOf(valuePropType)])
    .isRequired,
  setValue: PropTypes.func.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ).isRequired,
  multiple: PropTypes.bool,
  label: PropTypes.string.isRequired,
  nameFormater: PropTypes.func,
  emptyLabel: PropTypes.string,
  emptyValue: PropTypes.string,
  isTitle: PropTypes.bool,
  loading: PropTypes.bool,
  styleProp: PropTypes.object,
  fullWidth: PropTypes.bool,
};

ReviewSelectStandard.defaultProps = {
  open: false,
  multiple: false,
  nameFormater: null,
  emptyLabel: null,
  emptyValue: null,
  isTitle: false,
  loading: false,
  styleProp: null,
  fullWidth: false,
  setOpen: null,
};

export default ReviewSelectStandard;
