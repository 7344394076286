import React, { useEffect, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import {
  Alert,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Switch,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import shortZoneProps from "../../props/zoneProps";
import siteProps from "../../props/siteProps";

import uploadFileToBlob from "../../utils/uploadImage";
import ImageUploadButton from "../inputs/ImageUploadButton";
import { CAMERA_AND_SCALES, SCALES_ONLY } from "./constants/typeChoices";
import { fetchFoods, selectFoods } from "../../redux/foods";
import patchDevice from "./axios/patchDevice";
import { updateDevices } from "../../redux/devices";
import { NO_IMAGE } from "../../constants/urls";

function EditDeviceForm({
  zones,
  sites,
  selectedDevice,
  setDevices,
  handleEditDeviceCloseDialog,
  mixpanelSubmit,
}) {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { foods } = useSelector(selectFoods);
  const [x, y, width, height] = selectedDevice.bounding_area || [];
  const [items, setItems] = useState([]);

  useEffect(() => {
    dispatch(fetchFoods({ fields: ["id", "name"] }));
  }, [dispatch]);

  useEffect(() => {
    const currentSite = sites.find((el) => {
      const deviceId = el.devices.find((d) => d.id === selectedDevice.id);
      if (deviceId && deviceId.id) {
        return el;
      }
      return null;
    });
    if (currentSite && currentSite.devices.length) {
      const devicesNames = currentSite.devices.map((d) => d.name);
      const result = zones.filter((z) => {
        if (z.name === selectedDevice.name) {
          return true;
        }
        return !devicesNames.includes(z.name);
      });
      setItems(result);
    } else {
      setItems(zones);
    }
  }, [zones]);

  return (
    <Formik
      enableReinitialize
      initialValues={{
        deviceId: selectedDevice.id,
        x,
        y,
        width,
        height,
        photo: selectedDevice.kitchen_photo,
        file: null,
        zone: selectedDevice.name,
        type: selectedDevice.type,
        autoLabel: selectedDevice.auto_label,
        autoLabelFood: selectedDevice.auto_label_food.id,
      }}
      isInitialValid
      validateOnChange={false}
      validateOnMount={false}
      validationSchema={Yup.object().shape({
        deviceId: Yup.number().required(),
        x: Yup.number().nullable(),
        y: Yup.number().nullable(),
        width: Yup.number().nullable(),
        height: Yup.number().nullable(),
        file: Yup.mixed().nullable(),
        photo: Yup.string().nullable(),
        type: Yup.string()
          .oneOf(["SCALES_ONLY", "CAMERA_AND_SCALES"])
          .required(),
        autoLabel: Yup.boolean().required(),
        autoLabelFood: Yup.number().required(),
      })}
      onSubmit={async (values, { setErrors, setSubmitting }) => {
        const data = {
          name: values.zone ? values.zone : null,
          type: values.type,
          auto_label: values.autoLabel,
          auto_label_food: values.autoLabelFood,
          expand: "auto_label_food",
        };

        if (values.file && values.photo !== NO_IMAGE) {
          const exception = values.file.name.split(".").pop();
          const blobName = `devices/${selectedDevice.serial}/kitchen_photo.${exception}`;
          const url = await uploadFileToBlob({
            blobName,
            file: values.file,
          });
          data.kitchen_photo = url;
        }

        if (values.x && values.y && values.width && values.height) {
          data.bounding_area = [
            values.x,
            values.y,
            values.width,
            values.height,
          ];
        }

        const response = await patchDevice({
          id: values.deviceId,
          data,
          enqueueSnackbar,
        });

        if (response.error) {
          setErrors(response.error.response || response.error);
        } else {
          setDevices((prev) =>
            prev.map((device) => {
              if (device.id === values.deviceId) {
                return {
                  ...device,
                  ...response.data,
                };
              }
              return device;
            })
          );
          dispatch(updateDevices(response.data));
          handleEditDeviceCloseDialog();
        }

        setSubmitting(true);
      }}
    >
      {({
        errors,
        handleBlur,
        handleChange,
        handleSubmit,
        setFieldValue,
        isSubmitting,
        touched,
        values,
      }) => (
        <form noValidate onSubmit={handleSubmit}>
          {errors.submit && (
            <Alert mt={2} mb={3} severity="warning">
              {errors.submit}
            </Alert>
          )}
          <InputLabel>Bounding Area</InputLabel>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <TextField
              fullWidth
              id="bounding-area-x"
              name="x"
              label="X-coordinate"
              type="number"
              value={values.x}
              error={Boolean(touched.x && errors.x)}
              helperText={touched.x && errors.x}
              onBlur={handleBlur}
              onChange={handleChange}
              variant="standard"
            />
            <TextField
              fullWidth
              id="bounding-area-y"
              name="y"
              label="Y-coordinate"
              type="number"
              value={values.y}
              variant="standard"
              error={Boolean(touched.y && errors.y)}
              helperText={touched.y && errors.y}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Stack>
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={4}
          >
            <TextField
              fullWidth
              id="bounding-area-width"
              name="width"
              label="Width"
              type="number"
              value={values.width}
              variant="standard"
              error={Boolean(touched.width && errors.width)}
              helperText={touched.width && errors.width}
              onBlur={handleBlur}
              onChange={handleChange}
            />
            <TextField
              fullWidth
              id="bounding-area-height"
              name="height"
              label="Height"
              type="number"
              value={values.height}
              variant="standard"
              error={Boolean(touched.height && errors.height)}
              helperText={touched.height && errors.height}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </Stack>
          <FormControl fullWidth variant="standard" sx={{ my: 2 }}>
            <ImageUploadButton
              url={values.photo}
              errors={errors}
              touched={touched}
              values={values}
              setFieldValue={setFieldValue}
            />
            <FormHelperText>{errors.photo}</FormHelperText>
            <FormHelperText>{errors.file}</FormHelperText>
          </FormControl>
          <FormControl fullWidth variant="standard" sx={{ my: 4 }}>
            <InputLabel id="zone-select">Zone</InputLabel>
            <Select
              labelId="zone-select"
              name="zone"
              value={values.zone}
              onChange={handleChange}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {items.map((zone) => (
                <MenuItem key={zone.id} value={zone.name}>
                  {zone.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth variant="standard" sx={{ my: 2 }}>
            <InputLabel id="type-select">Type</InputLabel>
            <Select
              labelId="type-select"
              name="type"
              value={values.type}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              <MenuItem value="CAMERA_AND_SCALES">
                <em>{CAMERA_AND_SCALES}</em>
              </MenuItem>
              <MenuItem value="SCALES_ONLY">
                <em>{SCALES_ONLY}</em>
              </MenuItem>
            </Select>
            {touched.type && errors.type && (
              <FormHelperText>{errors.type}</FormHelperText>
            )}
          </FormControl>
          <FormControlLabel
            label="Auto label"
            control={
              <Switch
                name="autoLabel"
                checked={values.autoLabel}
                onBlur={handleBlur}
                onChange={handleChange}
                // onChange={({ target }) =>
                //   setFieldValue("autoLabel", target.checked)
                // }
              />
            }
          />
          <FormControl fullWidth variant="standard" sx={{ my: 2 }}>
            <InputLabel id="auto-label-food-select">Auto Label Food</InputLabel>
            <Select
              labelId="auto-label-food-select"
              name="autoLabelFood"
              value={values.autoLabelFood}
              onBlur={handleBlur}
              onChange={handleChange}
            >
              {foods.map((s) => (
                <MenuItem key={s.id} value={s.id}>
                  {s.name}
                </MenuItem>
              ))}
            </Select>
            {touched.autoLabelFood && errors.autoLabelFood && (
              <FormHelperText>{errors.autoLabelFood}</FormHelperText>
            )}
          </FormControl>

          <Button
            fullWidth
            sx={{ height: "50px", my: 4 }}
            type="submit"
            variant="contained"
            color="primary"
            disabled={isSubmitting}
            onClick={mixpanelSubmit}
          >
            Save
          </Button>
        </form>
      )}
    </Formik>
  );
}

EditDeviceForm.propTypes = {
  zones: PropTypes.arrayOf(PropTypes.shape(shortZoneProps)).isRequired,
  sites: PropTypes.arrayOf(PropTypes.shape(siteProps)).isRequired,
  selectedDevice: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    zone_id: PropTypes.number,
    type: PropTypes.string.isRequired,
    bounding_area: PropTypes.arrayOf(PropTypes.number),
    kitchen_photo: PropTypes.string,
    auto_label: PropTypes.bool.isRequired,
    auto_label_food: PropTypes.shape({
      id: PropTypes.number.isRequired,
    }).isRequired,
    serial: PropTypes.string.isRequired,
  }),
  handleEditDeviceCloseDialog: PropTypes.func.isRequired,
  mixpanelSubmit: PropTypes.func.isRequired,
  setDevices: PropTypes.func.isRequired,
};

EditDeviceForm.defaultProps = {
  selectedDevice: null,
};

export default EditDeviceForm;
