import React from "react";
import PropTypes from "prop-types";
import { Button, Card, CardContent, CardActions } from "@mui/material";
import CustomButton from "../components/Buttons/CustomButton";
import GroupService from "../../services/GroupService";
import { useSnackbar } from "notistack";

function FormDeleteGroup({ id, handleCancel }) {
  const { enqueueSnackbar } = useSnackbar();

  async function handleConfirm() {
    try {
      await GroupService.delete({ id });
      handleCancel();
    } catch (error) {
      enqueueSnackbar(error, { variant: "error" });
    }
  }

  return (
    <Card>
      <CardActions>
        <CustomButton onClick={handleCancel}>Cancel</CustomButton>
        <CustomButton onClick={handleConfirm}>Confirm</CustomButton>
      </CardActions>
    </Card>
  );
}

FormDeleteGroup.propTypes = {
  id: PropTypes.number.isRequired,
};

export default FormDeleteGroup;
