import { baseAxios } from "../../../utils/axios";

const eventDiscard = async (
  selectedIds,
  user,
  unlabelledEvent,
  setDevice,
  clearingValues,
  enqueueSnackbar
) => {
  const data = {
    status: "DISCARDED",
    user: user.id,
  };
  const id = selectedIds[0];
  const url = `api/labelling/${id}`;

  try {
    const { status } = await baseAxios.patch(url, data);

    if (status === 204) {
      setDevice(null);
    } else {
      setDevice((prevDevice) => {
        return { ...prevDevice, irl: prevDevice.irl - 1 };
      });
    }
  } catch (error) {
    enqueueSnackbar("Oops, something went wrong.", {
      variant: "error",
    });
  }

  clearingValues();
};

export default eventDiscard;
