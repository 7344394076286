import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Typography, Stack, Avatar } from "@mui/material";
import styled from "@emotion/styled";

import dayjs from "dayjs";
import PreviousValue from "../../overview/components/PreviousValue";
import HeaderValueBlock from "../../cards/components/HeaderValueBlock";
import { defaultFoodImage } from "../../../constants/urls";

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.color.gray,
}));

const InsightsChartHeader = ({
  url,
  title,
  upperValue,
  lowerValue,
  additionalValue,
  diffValue,
  startDate,
  endDate,
  withImage,
}) => {
  const [imageUrl, setImageUrl] = React.useState(defaultFoodImage);

  const errorHandler = () => {
    setImageUrl(defaultFoodImage);
  };

  useEffect(
    () => setImageUrl(!url || !url.endsWith(".svg") ? defaultFoodImage : url),
    [url]
  );

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="flex-start"
        spacing={2}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          spacing={2}
        >
          {withImage && (
            <Avatar
              variant="rounded"
              src={imageUrl}
              imgProps={{ onError: errorHandler }}
            >
              <img src={defaultFoodImage} />
            </Avatar>
          )}
          <Stack
            direction="column"
            justifyContent="flex-start"
            alignItems="flex-start"
            spacing={2}
          >
            <Title>{title || "Title is missing"}</Title>
            <PreviousValue prevValue={diffValue} date={[startDate, endDate]} />
          </Stack>
        </Stack>
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-end"
          spacing={2}
        >
          <HeaderValueBlock variant="h2" {...upperValue} />
          <HeaderValueBlock variant="subtitle1" {...lowerValue} />
          {additionalValue && (
            <HeaderValueBlock variant="subtitle1" {...additionalValue} />
          )}
        </Stack>
      </Stack>
    </>
  );
};

InsightsChartHeader.defaultProps = {
  withImage: false,
};

InsightsChartHeader.propTypes = {
  title: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  diffValue: PropTypes.number.isRequired,
  unit: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(dayjs).isRequired,
  endDate: PropTypes.instanceOf(dayjs).isRequired,
  withImage: PropTypes.bool,
};

export default InsightsChartHeader;
