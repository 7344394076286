import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { Card } from "@mui/material";
import { useModal } from "mui-modal-provider";
import { useDispatch, useSelector } from "react-redux";

import FeedCardHeader from "./components/FeedCardHeader";
import FeedCardActions from "./components/FeedCardActions";
import FeedCardContent from "./components/FeedCardContent";
import SimpleDialog from "../dialogs/SimpleDialog";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import { selectUser } from "../../redux/user";
import EditEventForm from "../feed/EditEventForm";
import { removeEvent } from "../../redux/reducers/feed";
import axiosInstance from "../../utils/axios";
import ExpandImages from "../dialogs/components/ExpandImages";
import CommentForm from "../feed/CommentForm";

function FeedCard(props) {
  const {
    id,
    site,
    food,
    food_name,
    site_name,
    processed_at,
    trimmings,
    item_weight,
    value,
    water,
    environment,
    status,
    food_image,
    bounding_area,
    tags,
    comment_id,
    comment_message,
    params,
  } = props;
  const dispatch = useDispatch();

  const { showModal } = useModal();

  const user = useSelector(selectUser);

  const handleDiscard = useCallback(() => {
    const modalDiscard = showModal(SimpleDialog, {
      title: `Discard Event`,
      handleClose: () => modalDiscard.hide(),
      content: (
        <ConfirmDialog
          description={`Are you sure you want to discard ${food_name} from ${site_name} at ${processed_at}?`}
          onConfirm={async () => {
            await axiosInstance.patch(`api/events/${id}/`, {
              user: user.id,
              status: "DISCARDED",
            });
            dispatch(removeEvent({ id }));
            modalDiscard.hide();
          }}
          params={params}
          onCancel={() => modalDiscard.hide()}
        />
      ),
    });

    return modalDiscard;
  }, [params, food_name, site_name, processed_at]);

  const handleEdit = () => {
    const modalEdit = showModal(SimpleDialog, {
      title: `Edit ${food_name}`,
      maxWidth: "xs",
      fullWidth: true,
      handleClose: () => modalEdit.hide(),
      content: (
        <EditEventForm
          site={site}
          updateParams={{ trimmings, status, food }}
          id={id}
          params={params}
          onCancel={() => modalEdit.hide()}
        />
      ),
    });

    return modalEdit;
  };

  const handleExpand = () => {
    const modalExpand = showModal(SimpleDialog, {
      title: `Expand ${food_name}`,
      maxWidth: "lg",
      fullWidth: true,
      handleClose: () => modalExpand.hide(),
      content: <ExpandImages event={props} boundingArea={bounding_area} />,
    });
  };

  const handleComment = () => {
    const modalComment = showModal(SimpleDialog, {
      title: "Comment",
      maxWidth: "xs",
      fullWidth: true,
      handleClose: () => modalComment.hide(),
      content: (
        <CommentForm
          eventId={id}
          commentId={comment_id}
          onCancel={() => modalComment.hide()}
        />
      ),
    });

    return modalComment;
  };

  return (
    <Card sx={{ p: 4 }}>
      <FeedCardHeader
        food={food_name}
        site={site_name}
        processedAt={processed_at}
        weight={item_weight}
        value={value}
        water={water}
        environment={environment}
        foodImage={food_image}
      />
      <FeedCardContent
        event={props}
        boundingArea={bounding_area}
        tags={tags}
        comment={comment_message}
        handleComment={handleComment}
      />
      <FeedCardActions
        handleDiscard={handleDiscard}
        handleEdit={handleEdit}
        handleComment={handleComment}
        handleExpand={handleExpand}
        hasComment={!!comment_id}
      />
    </Card>
  );
}

FeedCard.propTypes = {
  id: PropTypes.number.isRequired,
  food_name: PropTypes.string.isRequired,
  site_name: PropTypes.string.isRequired,
  logged_at: PropTypes.string.isRequired,
  item_weight: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
  image_url: PropTypes.string.isRequired,
  bounding_area: PropTypes.string.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default FeedCard;
