import { baseAxios } from "../../../utils/axios";

const getLabellingFoods = async ({ siteId }) => {
  const url = "api/foods/labelling/";
  const params = siteId ? { site_id: siteId } : {};

  const response = await baseAxios.get(url, { params });
  return response.data || [];
};

export default getLabellingFoods;
