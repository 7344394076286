import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { FormGroup } from "@mui/material";
import { fetchProfile, selectProfile, setProfile } from "../../redux/profile";
import { baseAxios } from "../../utils/axios";
import SwitchFilter from "../filter/components/SwitchFilter";
import { selectUser } from "../../redux/user";
import WhiteBlock from "../blocks/WhiteBlock";

function Notifications() {
  const { id } = useSelector(selectUser);

  const dispatch = useDispatch();

  const { enqueueSnackbar } = useSnackbar();

  const { data } = useSelector(selectProfile);

  const [daily, setDaily] = useState(data.daily_report);
  const [weekly, setWeekly] = useState(data.weekly_report);
  const [monthly, setMonthly] = useState(data.monthly_report);

  function setReports(data) {
    setDaily(data.daily_report);
    setWeekly(data.weekly_report);
    setMonthly(data.monthly_report);
  }

  useEffect(() => {
    if (data.id) {
      setReports(data);
    }
  }, [data]);

  useEffect(() => {
    if (!data.id && id) {
      dispatch(fetchProfile(id));
    }
  }, [id]);

  useEffect(async () => {
    if (!id) return;

    const isChange =
      daily !== data.daily_report ||
      weekly !== data.weekly_report ||
      monthly !== data.monthly_report;

    if (isChange) {
      try {
        const response = await baseAxios.patch(`api/profile/${data.id}/`, {
          daily_report: daily,
          weekly_report: weekly,
          monthly_report: monthly,
        });
        dispatch(setProfile(response.data));
      } catch (error) {
        enqueueSnackbar("Oops, something went wrong.", { variant: "error" });
        setReports(data);
      }
    }
  }, [daily, weekly, monthly]);

  return (
    <WhiteBlock title="Notifications">
      <FormGroup>
        <SwitchFilter
          label="Daily Food Waste Report"
          value={daily}
          setValue={setDaily}
          clickable={false}
        />
        <SwitchFilter
          label="Weekly Food Waste Report"
          value={weekly}
          setValue={setWeekly}
          clickable={false}
        />
        <SwitchFilter
          label="Monthly Food Waste Report"
          value={monthly}
          setValue={setMonthly}
          clickable={false}
        />
      </FormGroup>
    </WhiteBlock>
  );
}

export default Notifications;
