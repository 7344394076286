import {baseAxios} from "../axios";

export const getRecommendedTarget = (siteId, type = 'monthly_snapshot') => (
  baseAxios.get(`api/overview/recommended_waste_target/?site=${siteId}&widget=${type}`)
    .then(res => (
      {
        target: res.data.proposed_target,
        message: res.data.message
      }
    ))
)
