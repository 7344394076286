import React from "react";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";

import CardButton from "./CardButton";

const InsightsChartFooter = ({ buttons }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      spacing={2}
    >
      {buttons.map((button) => (
        <CardButton
          name={button.name}
          icon={button.icon}
          onClick={button.onClick}
        />
      ))}
    </Stack>
  );
};

InsightsChartFooter.defaultProps = {
  buttons: [],
};

InsightsChartFooter.propTypes = {
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.element.isRequired,
      onClick: PropTypes.func.isRequired,
    })
  ),
};

export default InsightsChartFooter;
