import React, { useEffect, useRef, useState } from "react";

import PropTypes from "prop-types";

import { useDispatch } from "react-redux";

import { DataGrid, gridStringOrNumberComparator } from "@mui/x-data-grid";

import { Avatar, Chip, Paper, Typography } from "@mui/material";
import TimeAgo from "timeago-react";
import { useSnackbar } from "notistack";
import { CustomCheckbox } from "../styled/GlobalStyled";
import { COLORS } from "../../constants/base";

import { updateUser } from "../../redux/user";
import { getUserId } from "../../utils/users/getUserId";
import fetchUsers from "../../utils/users/fetchUsers";
import axiosDeleteUser from "../../utils/users/axiosDeleteUser";
import { titleCase } from "../../utils/formaters/titleCase";
import { localDateForUsers } from "../../utils/date/localDate";
import { stringAvatar } from "../../utils/avatar";
import useResizeDataGrid from "../../hooks/useResizeDataGrid";
import {
  COLUMNS_WIDTH,
  gridStyles,
  headerHeight,
  minHeight,
  rowBuffer,
  rowHeight,
  rowThreshold,
} from "../../constants/dataGrid";
import handleRenderTooltipDoubleCell from "../../utils/table/handleRenderTooltipDoubleCell";

import ActionButtons from "./ActionButtons";

function UserTable({
  selectedRole,
  selectedSite,
  selectedParentGroup,
  dataUsers,
  setDataUsers,
  paginationModel,
  setPaginationModel,
  sortModel,
  setSortModel,
  dataParentGroups,
  dataSites,
  limit,
  width,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();

  const [height, setHeight] = useState(`${minHeight}px`);
  const [rowCountState, setRowCountState] = useState(0);
  const [loading, setLoading] = useState(false);
  const [isFirstLoading, setIsFirstLoading] = useState(true);
  const [isLoadData, setIsLoadData] = useState(false);
  const [selectionModel, setSelectionModel] = useState([]);
  const [deletingProcess, setDeletingProcess] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const userId = getUserId();
    // eslint-disable-next-line no-shadow
    const user = dataUsers.find((user) => user.id === userId);
    dispatch(updateUser(user));
  }, [dataUsers]);

  const handleDelete = () => {
    setDeletingProcess(true);
    Promise.all(
      selectionModel.map(async (id) => {
        await axiosDeleteUser({ id, setDataUsers, enqueueSnackbar });
      })
    ).then(() => setDeletingProcess(false));
  };

  const handlerCellClick = (params) => {
    if (params.field === "__check__") return;

    if (selectionModel.includes(params.id)) {
      if (params.field !== "actions") {
        const selectedIds = selectionModel.filter((x) => x !== params.id);
        setSelectionModel(selectedIds);
      }
    } else {
      setSelectionModel([...selectionModel, params.id]);
    }
  };

  const nameComparator = (v1, v2) => {
    return gridStringOrNumberComparator(
      v1.first_name,
      v2.first_name,
      v1.surname,
      v2.surname
    );
  };

  const siteComparator = (v1, v2) => {
    if (v1.count > 1 || v2.count > 1) {
      return v1.count - v2.count;
    }
    if (v1.name === v2.name) {
      return 0;
    }
    if (v1.name > v2.name) {
      return 1;
    }
    return -1;
  };

  const columns = [
    {
      field: "name",
      headerName: "Name",
      ...COLUMNS_WIDTH.default,
      valueGetter: (params) => ({
        first_name: params.row.first_name,
        surname: params.row.surname,
      }),
      sortComparator: nameComparator,
      renderCell: (params) => {
        const stylesWithPhoto = {
          width: "24px",
          height: "24px",
          borderRadius: "50px",
          marginRight: "10px",
        };
        const stylesWithoutPhoto = {
          ...stylesWithPhoto,
          fontSize: "12px",
        };
        const fullName = `${params.row.first_name} ${params.row.surname}`;

        return (
          <div style={{ display: "flex", alignItems: "center" }}>
            {params.row.profile_photo ? (
              <Avatar
                src={params.row.profile_photo}
                alt={`${params.row.first_name}, ${params.row.surname}`}
                sx={stylesWithPhoto}
              />
            ) : (
              <Avatar
                alt={`${params.row.first_name}, ${params.row.surname}`}
                {...stringAvatar(
                  params.row.first_name,
                  params.row.surname,
                  stylesWithoutPhoto
                )}
              />
            )}
            <Typography variant="h3" sx={{ fontWeight: 500 }}>
              {handleRenderTooltipDoubleCell(fullName)}
            </Typography>
          </div>
        );
      },
    },
    {
      field: "email",
      headerName: "Email",
      ...COLUMNS_WIDTH.default,
      valueFormatter: ({ value }) => value || "-",
      renderCell: ({ formattedValue }) => {
        handleRenderTooltipDoubleCell(formattedValue);
      },
    },
    {
      field: "parent_group",
      headerName: "Parent Group",
      ...COLUMNS_WIDTH.default,
      valueFormatter: ({ value }) => value || "-",
      renderCell: ({ formattedValue }) => {
        handleRenderTooltipDoubleCell(formattedValue);
      },
    },
    {
      field: "site",
      headerName: "Site",
      ...COLUMNS_WIDTH.default,
      valueGetter: ({ row }) => ({
        count: row.site.length,
        name: row.site.length ? row.site[0] && row.site[0].name : "",
      }),
      sortComparator: siteComparator,
      renderCell: ({ row, value }) => {
        const name =
          value.count > 1 ? `Multi ${value.count}` : value.name || "-";
        const nameTooltip = row.site.map((s) => s.name).join(", ");
        return handleRenderTooltipDoubleCell(name, null, nameTooltip);
      },
    },
    {
      field: "role",
      headerName: "Role",
      ...COLUMNS_WIDTH.dateTime,
      renderCell: (params) => {
        const { role } = params.row;
        const label = role ? titleCase(role) : "-";
        let colorRole;
        let colorText;

        if (role === "Admin") {
          colorRole = COLORS.yellow;
          colorText = COLORS.white;
        } else if (role === "Labeller") {
          colorRole = COLORS.green;
          colorText = COLORS.white;
        } else if (role === "User") {
          colorRole = COLORS.purple;
          colorText = COLORS.white;
        } else {
          colorRole = "transparent";
          colorText = "#000";
        }

        return (
          <Chip
            size="small"
            label={label}
            sx={{
              backgroundColor: colorRole,
              color: colorText,
              width: "84px",
              fontWeight: 500,
            }}
          />
        );
      },
    },
    {
      field: "last_activity",
      headerName: "Last Active",
      ...COLUMNS_WIDTH.dateTime,
      renderCell: ({ row }) => {
        const date = new Date(row.last_activity);
        const current = Date.now();
        const diff = current - date;
        const days = diff / (1000 * 60 * 60 * 24);
        if (row.last_activity && days >= 2) {
          return (
            <Typography variant="body1" sx={{ color: COLORS.table_gray }}>
              {localDateForUsers(row.last_activity)}
            </Typography>
          );
        }
        return (
          <Typography variant="body1" sx={{ color: COLORS.table_gray }}>
            {row.last_activity ? (
              <TimeAgo
                datetime={row.last_activity}
                locale={navigator.language}
              />
            ) : (
              "-"
            )}
          </Typography>
        );
      },
    },
    {
      field: "actions",
      type: "actions",
      sortable: false,
      ...COLUMNS_WIDTH.action1,
      renderCell: ({ row }) => (
        <ActionButtons
          dataUsers={row}
          dataParentGroups={dataParentGroups}
          dataSites={dataSites}
          setDataUsers={setDataUsers}
          checkedIds={selectionModel}
          handleDelete={handleDelete}
          deletingProcess={deletingProcess}
        />
      ),
    },
  ];

  const getOrdering = (sortingModel) => {
    if (!sortingModel.length) return null;

    let ordering = sortingModel[0].sort === "desc" ? "-" : "";
    ordering += sortingModel[0].field;
    return ordering;
  };

  const getUsers = async ({ limit: l, offset, role, parentGroup, site }) => {
    const usersParams = {
      limit: l,
      offset,
    };
    if (role) usersParams.role = role;
    if (parentGroup) usersParams.parentGroup = parentGroup.map((el) => el.id);
    if (site) usersParams.site = site.map((el) => el.id);

    const ordering = getOrdering(sortModel);
    if (ordering) usersParams.ordering = ordering;

    const response = await fetchUsers({ params: usersParams });
    return response;
  };

  const getUsersData = async ({ role, parentGroup, site }) => {
    setLoading(true);

    const newUsers = await getUsers({
      limit,
      offset: limit * paginationModel.page,
      role,
      parentGroup,
      site,
    });

    if (!newUsers) return [];
    setRowCountState((newUsers && newUsers.count) || 0);
    return (newUsers && newUsers.results) || [];
  };

  useEffect(async () => {
    if (isFirstLoading) {
      setIsFirstLoading(false);
    }

    setPaginationModel({ ...paginationModel, page: 0 });
    const newUserData = await getUsersData({
      role: selectedRole,
      parentGroup: selectedParentGroup,
      site: selectedSite,
    });

    setDataUsers(newUserData || []);
    useResizeDataGrid({ setHeight, ref, data: newUserData });
    setLoading(false);
    if (isLoadData) {
      setIsLoadData(false);
    }
  }, [selectedRole, selectedSite, selectedParentGroup]);

  useEffect(async () => {
    if (!isLoadData) return;

    const newUserData = await getUsersData({
      role: selectedRole,
      parentGroup: selectedParentGroup,
      site: selectedSite,
    });

    setDataUsers(newUserData);
    useResizeDataGrid({ setHeight, ref, data: newUserData });
    setLoading(false);
    setIsLoadData(false);
  }, [isLoadData]);

  return (
    <Paper
      elevation={3}
      style={{
        height,
        width: width || "100%",
      }}
    >
      <DataGrid
        sortingOrder={["desc", "asc"]}
        ref={ref}
        key="dataDrid-UserTable"
        sx={gridStyles}
        hideFooterPagination={isLoadData}
        rowBuffer={rowBuffer}
        rowThreshold={rowThreshold}
        rows={dataUsers}
        rowHeight={rowHeight}
        headerHeight={headerHeight}
        columns={columns}
        rowCount={rowCountState}
        loading={loading}
        checkboxSelection
        disableColumnMenu
        pagination
        paginationModel={paginationModel}
        onPaginationModelChange={(newModel) => {
          setPaginationModel(newModel);
          setIsLoadData(true);
        }}
        paginationMode="server"
        sortModel={sortModel}
        onSortModelChange={(newSortModel) => {
          setSortModel(newSortModel);
          setIsLoadData(true);
        }}
        components={{ BaseCheckbox: CustomCheckbox }}
        rowSelectionModel={selectionModel}
        onRowSelectionModelChange={(newRowSelectionModel) => {
          setSelectionModel(newRowSelectionModel);
        }}
        onCellClick={handlerCellClick}
      />
    </Paper>
  );
}

UserTable.propTypes = {
  selectedRole: PropTypes.arrayOf(PropTypes.string),
  selectedSite: PropTypes.arrayOf(PropTypes.shape({})),
  selectedParentGroup: PropTypes.arrayOf(PropTypes.shape({})),
  dataUsers: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setDataUsers: PropTypes.func.isRequired,
  paginationModel: PropTypes.shape({
    page: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
  }).isRequired,
  setPaginationModel: PropTypes.func.isRequired,
  sortModel: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  setSortModel: PropTypes.func.isRequired,
  dataParentGroups: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  dataSites: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  limit: PropTypes.number.isRequired,
  width: PropTypes.number,
};

UserTable.defaultProps = {
  selectedRole: [],
  selectedSite: [],
  selectedParentGroup: [],
  width: undefined,
};

export default UserTable;
