export const defaultFoodImage =
  "https://deviceimagefeed.blob.core.windows.net/foodimages/Frucht.svg";

export const authUrls = {
  login: "/api/auth/login/",
  register: "/api/auth/register/",
  passwordReset: "/api/auth/password/reset/",
  passwordResetComplete: "/api/auth/password/reset/complete/",
  passwordChange: "/api/auth/password/change",
  tokenRefresh: "/api/auth/token/refresh/",
};

export const NO_IMAGE =
  "https://deviceimagefeed.blob.core.windows.net/main/no_image_600x400.svg";


export const routerBase = {
  auth: "auth",
  signIn: "sign-in",
  signUp: "sign-up",
}

export const routerPaths = {
  signIn: `/${routerBase.auth}/${routerBase.signIn}`,
  signUp: `/${routerBase.auth}/${routerBase.signUp}`,
}

export const mainRoutes = {
  explore: 'explore',
  widgets: 'widgets',
}
