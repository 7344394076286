import React from 'react';
import {Stack, Typography} from "@mui/material";
import Label from "../../components/Label";
import numberFormater from "../../../../utils/formaters/numberFormater";
import Grid from "@mui/material/Grid";
import { defaultFoodImage } from "../../../../constants/urls";
import ProgressBar from "./ProgresBar";
import {pipe} from "../../../../utils/pipe";
import {getTargetByMetric} from "../../MonthlySnapshot/helpers";
import {formatCoastValue} from "../../../../utils/formaters/formatCoastValue";
import {useTheme} from "@mui/material/styles";

const Food = ({
  name,
  image_url: imageUrl,
  subcategory,
  monthly_targets: monthlyTargets,
  unit,
  value,
  metric,
  max
}) => {
  const total = numberFormater({  value, fixed: 1, after: unit})
  const image = (!imageUrl || imageUrl === '-') ? defaultFoodImage : imageUrl
  const monthlyTarget = pipe(monthlyTargets, getTargetByMetric(metric))
  const theme = useTheme()
  return (
    <Grid container alignItems="center" rowSpacing={6}>
      <Grid item xs={12} xl={4}>
        <Label description={subcategory} Icon={image} title={name}/>
      </Grid>
      <Grid xs={8} xl={5} item>
        <ProgressBar value={value} target={monthlyTarget} unit={unit} max={max} />
      </Grid>
      <Grid xs={4} xl={3} item>
        <Stack direction="row" justifyContent="flex-end">
          <Typography color={theme.palette.widget.label} overflow="hidden" textOverflow="ellipsis" alignItems="right" variant="medium">{formatCoastValue(total)}</Typography>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Food;
