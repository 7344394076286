import React, { useMemo } from "react";
import styled from "@emotion/styled";
import {
  Box,
  List,
  ListItemButton,
  ListItemText,
  Grid,
  Typography as MuiTypography,
} from "@mui/material";
import LabelIcon from "@mui/icons-material/Label";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import PropTypes from "prop-types";

import { ReactComponent as Logo } from "../icons/sidebar.svg";
import { Items } from "./SidebarNav";
import useAppSelector from "../../hooks/useAppSelector";
import { selectUser } from "../../redux/user";
import LocalStorageService from "../../services/LocalStorageService";

const SideBar = styled(Logo)`
  margin-right: ${(props) => props.theme.spacing(2)};
  color: ${(props) => props.theme.sidebar.icon.color};
  fill: ${(props) => props.theme.sidebar.icon.color};
  vertical-align: middle;
  display: inline;
`;

const Footer = styled.div`
  background-color: ${(props) => props.theme.sidebar.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(0)};
`;

const Title = styled(ListItemText)`
  color: ${(props) => props.theme.sidebar.color};
  margin: 0;

  span {
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.1px;
    font-weight: 500;
    padding: 0 ${(props) => props.theme.spacing(4)};
  }
`;

const Item = styled(ListItemButton)(({ theme, toggle }) => ({
  paddingLeft: theme.spacing(6),
  fontWeight: theme.typography.fontWeightRegular,

  svg: {
    fontSize: "20px",
    width: "20px",
    height: "20px",
    color: toggle ? theme.sidebar.icon.color : theme.sidebar.active.color,

    path: {
      fill: toggle ? theme.sidebar.icon.color : theme.sidebar.active.color,
    },
  },
}));

const Typography = styled(MuiTypography)(({ theme }) => ({
  padding: "0 !important",
  color: theme.palette.color.accentBlue,
}));

function SidebarFooter({ toggle, handleToggle, open, }) {
  const user = useAppSelector(selectUser);
  const isAdmin = useMemo(
    () => (user.role ? user.role.toLowerCase() === "admin" : false),
    [user]
  );

  const DOCUMENTATION_LINK =
    "https://www.notion.so/positivecarbon/Positive-Carbon-Knowledge-Hub-2a58970844c34c60b54bb5f7a2e43fa0";
  const handleOpenDocumentation = () => {
    window.open(DOCUMENTATION_LINK, "_blank");
  };

  return (
    <Footer>
      <Grid>
        <List disablePadding>
          <Items>
            <Item
              sx={{ overflow: "hidden" }}
              component="nav"
              onClick={handleOpenDocumentation}
              toggle={true}
            >
              <div style={{ display: "flex" }}>
                <MenuBookIcon sx={{ mr: 2 }} />
              </div>
              {toggle && (
                <Box>
                  <Title sx={{ width: "182px" }}>Knowledge Base</Title>
                </Box>
              )}
            </Item>
          </Items>
        </List>
      </Grid>
    </Footer>
  );
}

SidebarFooter.propTypes = {
  toggle: PropTypes.bool.isRequired,
  handleToggle: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  handleViewAs: PropTypes.func.isRequired,
};

export default SidebarFooter;
