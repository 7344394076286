import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Skeleton } from "@mui/material";
import ReactImageZoom from "react-image-zoom";

function ZoomImage({ imageUrl, disableZoom, width, height }) {
  const [isZoomImage, setIsZoomImage] = useState(false);

  useEffect(() => {
    if (imageUrl) {
      const image = new Image();
      image.src = imageUrl;
      image.onload = function () {
        setIsZoomImage(this.width > width && this.height > height);
      };
      image.onerror = function () {
        setIsZoomImage(false);
      };
    } else {
      setIsZoomImage(false);
    }
  }, [imageUrl, width, height]);

  return (
    <>
      {imageUrl ? (
        !disableZoom && isZoomImage ? (
          <Box
            component={ReactImageZoom}
            data-testid="blob-event-image"
            width={width}
            height={height}
            zoomPosition="original"
            img={imageUrl}
          />
        ) : (
          <Box
            component="img"
            width={width}
            height={height}
            src={imageUrl}
            alt=""
          />
        )
      ) : (
        <Skeleton
          data-testid="blob-event-image-skeleton"
          variant="rectangular"
          animation="wave"
          width="100%"
          height={height}
        />
      )}
    </>
  );
}

ZoomImage.propTypes = {
  imageUrl: PropTypes.string,
  disableZoom: PropTypes.bool,
  width: PropTypes.number,
  height: PropTypes.number,
};

ZoomImage.defaultProps = {
  imageUrl: null,
  disableZoom: false,
  width: 0,
  height: 0,
};

export default ZoomImage;
