import React, {createContext, useEffect, useMemo, useReducer, useState} from "react";
import PropTypes from "prop-types";

import { getUserId } from "../utils/users/getUserId";
import { getUser } from "../utils/users/getUser";
import {baseAxios, errorMessage} from "../utils/axios";
import { removeUser, setUser, updateUser } from "../redux/user";
import useAppDispatch from "../hooks/useAppDispatch";

import {
  getSessionStorageValue,
  setLocalStorageValue,
  setSessionStorageValue,
} from "../utils/storage";
import TokenService from "../services/TokenService";
import useMixpanelTrack from "../hooks/useMixpanelTrack";
import {useGoogleLogin} from "@react-oauth/google";
import {useLocation, useNavigate} from "react-router-dom";
import authService from '../services/Auth'
import {fetchProfile} from "../redux/profile";
import {resetStore} from "../redux/store";

const REFRESH_TOKEN = "refreshToken";
const ACCESS_TOKEN = "accessToken";

const INITIALIZE = "INITIALIZE";
const SIGN_IN = "SIGN_IN";
const SIGN_OUT = "SIGN_OUT";
const SIGN_UP = "SIGN_UP";
const POST_SIGN_UP = "POST_SIGN_UP";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const JWTReducer = (state, action) => {
  switch (action.type) {
    case INITIALIZE:
      return {
        isAuthenticated: action.payload.isAuthenticated,
        isInitialized: true,
        user: action.payload.user,
      };
    case SIGN_IN:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    case SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };

    case SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    case POST_SIGN_UP:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };

    default:
      return state;
  }
};

const clearSession = () => {
  sessionStorage.removeItem(REFRESH_TOKEN);
  sessionStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(ACCESS_TOKEN);
  if (getSessionStorageValue("user")) {
    TokenService.removeTokensCookies();
  }
  delete baseAxios.defaults.headers.common.Authorization;
}

const initUser = ({ logger, jwtDispatch, response}) => async dispatch =>  {
  const { token, user: currentUser } = response.data;
  const { access, refresh } = token;
  dispatch(setUser(currentUser));
  setLocalStorageValue("user", JSON.stringify(currentUser));
  setLocalStorageValue(ACCESS_TOKEN, access);
  setLocalStorageValue(REFRESH_TOKEN, refresh);

  jwtDispatch({
    type: SIGN_IN,
    payload: { user: currentUser },
  });

  // logger("Sign In - Success");
  return response;
}


const AuthContext = createContext(() => {
  return null;
});

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(JWTReducer, initialState);
  const [error, setError] = useState('')
  const appDispatch = useAppDispatch();
  const userId = getUserId();
  let user = getUser();
  const mixpanelTrack = useMixpanelTrack();
  const navigate = useNavigate()
  const { pathname } = useLocation()

  const handleErrorWithThrow = error => {
    setError(errorMessage(error))
    throw error
  }
  const handleError = error => {
    setError(errorMessage(error))
  }

  const handleLogin = res => appDispatch(initUser({
    jwtDispatch: dispatch,
    response: res,
    logger: mixpanelTrack
  }))
    .then(() => navigate('/'))

  const googleLogin = useGoogleLogin({
    onSuccess: async tokenResponse => {
      return await baseAxios
        .post('api/auth/social-login', {
          id_token: tokenResponse.access_token,
        })
        .then(handleLogin)
        .catch(handleError)
    },
  });

  const loginWithMagicLink = token => {
    clearSession()
    setError('')
    return authService.verifyMagicToken(token)
      .then(handleLogin)
      .catch(handleError)
  }

  const sendMagicLink = (email) => authService.createMagicLink(email)
    .then(() => setError(""))
    .catch(handleErrorWithThrow)

  useEffect(() => {
    const initialize = async () => {
      let isAuthenticated = false;
      try {
        if (user) {
          isAuthenticated = true;
        } else if (userId) {
          const response = await baseAxios.get(`/api/users/${userId}`);
          const userById = response.data;

          if (userById) {
            appDispatch(setUser(userById));
            user = userById;
            isAuthenticated = true;
          }
        } else {
          isAuthenticated = false;
          user = null;
        }

        // if (appConfig.isProduction) {
        //   mixpanel.identify(user.id);
        // }

        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated,
            user,
          },
        });
      } catch (err) {
        dispatch({
          type: INITIALIZE,
          payload: {
            isAuthenticated: false,
            user: null,
          },
        });
      }
    };

    initialize()
      .then(() => {
        if(userId) {
          appDispatch(fetchProfile(userId))
        }
      })
  }, [userId, appDispatch]);

  useEffect(() => {
    if (error) {
      setError('')
    }
  }, [state, pathname]);

  const signIn = async (email, password, keepLogin) => {
    clearSession()
    const url = "api/auth/login/";
    const response = await baseAxios.post(url, {
      email,
      password,
    });
    return appDispatch(initUser({
      jwtDispatch: dispatch,
      response,
      logger: mixpanelTrack,
    }))
  }

  const googleSignIn = async () => {
    clearSession()
    return googleLogin()
  }
  const signOut = async () => {
    sessionStorage.clear();
    localStorage.clear();
    TokenService.removeTokensCookies();
    appDispatch(removeUser());
    appDispatch(resetStore())
    dispatch({ type: SIGN_OUT });
    mixpanelTrack("Sign Out");
  };

  const signUp = async (
    email,
    password,
    firstName,
    surname,
    employer,
    headOffice,
    signUpId,
    isActive,
    group
  ) => {
    const data = {
      email,
      password,
      first_name: firstName,
      surname,
      employer,
      headOffice,
      is_active: isActive,
    };
    if (group) data.parent_group_id = parseInt(group, 10);
    if (signUpId) data.signup_id = signUpId;

    try {
      const response = await baseAxios.post("/api/auth/register/", data);
      const { user: newUser } = response.data;

      dispatch({
        type: SIGN_UP,
        payload: {
          user: newUser,
        },
      });

      mixpanelTrack("Sign Up - Success");
      return response;
    } catch (error) {
      mixpanelTrack("Sign Up - Fail");
      throw error;
    }
  };

  const resetPassword = async (email) => {
    try {
      const response = await baseAxios.post("/api/auth/password/reset/", {
        email,
      });
      mixpanelTrack("Reset Password - Success");
      return response;
    } catch (error) {
      mixpanelTrack("Reset Password - Fail");
      throw error;
    }
  };

  const resetPasswordComplete = async (password, token, uidb64) => {
    try {
      const response = await baseAxios.post(
        "/api/auth/password/reset/complete/",
        {
          password,
          token,
          uidb64,
        }
      );
      mixpanelTrack("Reset Password Complete - Success");
      return response;
    } catch (error) {
      mixpanelTrack("Reset Password Complete - Fail");
      throw error;
    }
  };

  const changePassword = async (currentPassword, newPassword) => {
    try {
      await baseAxios.post("/api/auth/password/change", {
        current_password: currentPassword,
        new_password: newPassword,
      });
      mixpanelTrack("Change Password - Success");
    } catch (error) {
      mixpanelTrack("Change Password - Fail");
      throw error;
    }
  };

  const editUser = async (
    profilePhoto,
    role,
    firstName,
    surname,
    email,
    phoneNumber,
    employer,
    jobTitle,
    parentGroup,
    site
  ) => {
    try {
      const response = await baseAxios.patch(`/api/users/${getUserId()}`, {
        profile_photo: profilePhoto,
        role,
        first_name: firstName,
        surname,
        email,
        phone_number: phoneNumber,
        employer,
        job_title: jobTitle,
        parent_group: parentGroup,
        site,
      });

      appDispatch(updateUser(response.data));

      if (getSessionStorageValue("user")) {
        setSessionStorageValue("user", JSON.stringify(response.data));
      } else {
        setLocalStorageValue("user", JSON.stringify(response.data));
      }
      mixpanelTrack("Edit User - Success");
    } catch (error) {
      mixpanelTrack("Edit User - Fail");
      throw error;
    }
  };

  const providerValue = useMemo(() => {
    return {
      ...state,
      method: "jwt",
      signIn,
      signOut,
      signUp,
      resetPassword,
      resetPasswordComplete,
      changePassword,
      editUser,
      googleSignIn,
      error,
      sendMagicLink,
      loginWithMagicLink,
    };
  }, [
    state,
    signIn,
    signOut,
    signUp,
    resetPassword,
    resetPasswordComplete,
    changePassword,
    editUser,
    googleSignIn,
    error,
    sendMagicLink,
    loginWithMagicLink,
  ]);

  return (
    <AuthContext.Provider value={providerValue}>
      {children}
    </AuthContext.Provider>
  );
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export { AuthContext, AuthProvider };
