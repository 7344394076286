import {useEffect, useState} from "react";
import {getLabels, mapChartLabels} from "../components/explore/chart/helpers";
import {pipe} from "../utils/pipe";
export const useChartLabels = (data, excludeFields, legend, predefinedOptions) => {
  const [activeLabels, setActiveLabels] = useState({})

  const toggleLabel = value => {
    const copy = {...activeLabels}
    copy[value].isActive = !activeLabels[value].isActive
    setActiveLabels(copy)
  }

  useEffect(() => {
    const labels = (data?.length ? pipe(data, mapChartLabels(excludeFields)) : [])
    setActiveLabels(getLabels(labels, legend, predefinedOptions))
  }, [data]);

  return {
    activeLabels,
    toggleLabel,
  }
}
