import React, {useMemo} from 'react';
import Base from "../Base";
import {formatDataQuery} from "../../../utils/date/formatDataQuery";
import {Stack} from "@mui/material";
import Button from "@mui/material/Button";
import dayjs from "dayjs";
import Loader from "../Loader";
import Chart from "./components/Chart";
import Placeholder from "../components/Placeholder";
import {useSelector} from "react-redux";
import {selectExplore} from "../../../redux/explore";
import {mepMetricParams} from "../../explore/helpers";
import {WIDGET_IDENTITY} from "../config";
import {useWidget} from "../useWidget";
import {mainRoutes} from "../../../constants/urls";
import {DATE_GRAN, LABELS} from "../../../constants/explore";

const startOfCurrentMonth = dayjs().startOf('month').format('YYYY-MM-DD')
const Trimmings = () => {
  const { filters } = useSelector(selectExplore)
  const selectedSite = filters.sites[0]
  const params = {
    startDate: startOfCurrentMonth,
    endDate: formatDataQuery(new Date()),
    metric: mepMetricParams[filters.metric],
    ...(selectedSite?.id ? { site: selectedSite.id} : {}),
  }

  const { data, isLoading, title, tip, goToExplore } = useWidget(WIDGET_IDENTITY.TRIMMINGS, {
    fetchKey: ['api/overview/trimmings', params],
    exploreParams: {
      params:  {
        dateRange: [
          startOfCurrentMonth,
          formatDataQuery(new Date()),
        ],
        sites: filters.sites,
        labels: LABELS.trimmings,
        dateGranularity: DATE_GRAN.weekly,
      },
      path: `/${mainRoutes.explore}`,
    }
  })

  const {
    chart_data: chartData = [],
    unit,
    total_value: totalValue,
  } = data || {}

  const isDataToShow = useMemo(() => {
    if(chartData.length) {
      return chartData.some(item => !!item.value)
    }
    return false
  }, [chartData])

  const displayValue = useMemo(() => chartData.find(item => item.name === 'Trimmings')?.percentage, [chartData])
  return (
    <Base title={title} tooltip={tip} value={displayValue ? `${displayValue}%` : null} canUnpin id={WIDGET_IDENTITY.TRIMMINGS}>
      <Base.Content>
        {isLoading && <Loader />}
        {isDataToShow && (
          <Chart data={chartData} unit={unit} />
        )}
        {!isDataToShow && (
          <Placeholder text="No Data"/>
        )}
      </Base.Content>
      <Base.Footer>
        <Stack direction="row" justifyContent="flex-end">
          <Button disabled={isLoading} variant="link" onClick={goToExplore}>Explore Trimmings</Button>
        </Stack>
      </Base.Footer>
    </Base>
  );
};

export default Trimmings;
