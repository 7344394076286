import React from "react";
import { MenuItem, Select } from "@mui/material";
import PropTypes from "prop-types";
import { COLORS } from "../../constants/base";

function WasteButton({ sm, select, selectOpen, setSelectOpen, handleChange }) {
  const selectItem = ["Full Waste", "Trimmings", "Escalate"];

  return (
    <Select
      value={select}
      open={selectOpen}
      onOpen={() => setSelectOpen(true)}
      onClose={() => setSelectOpen(false)}
      onChange={handleChange}
      size="medium"
      inputProps={{ id: "food-waste-select" }}
      sx={{
        backgroundColor: COLORS.green,
        minWidth: sm ? "100px" : "160px",
        paddingRight: "1rem",
        paddingLeft: "1rem",
        ".MuiSelect-outlined": {
          backgroundColor: COLORS.green,
          padding: "13px",
        },

        ".MuiSelect-select": {
          borderColor: COLORS.green,
          backgroundColor: COLORS.green,
          color: COLORS.white,
          fontSize: "11px",
          lineHeight: "16px",
          letterSpacing: "0.2px",
          width: "100%",
          margin: "auto",
        },
        ".MuiSelect-icon": {
          color: COLORS.white,
          marginRight: "1rem",
        },
        "&.Mui-focused .MuiSelect-icon": {
          color: COLORS.white,
          marginRight: "1rem",
        },

        ".MuiOutlinedInput-notchedOutline": {
          borderColor: COLORS.green,
        },
        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
          borderColor: COLORS.green,
        },
        "&:hover .MuiOutlinedInput-notchedOutline": {
          borderColor: COLORS.green,
        },
      }}
    >
      {selectItem.map((el) => (
        <MenuItem
          data-testid={`item-${el.split(" ")[0].toLowerCase()}`}
          key={el}
          value={el}
        >
          {el}
        </MenuItem>
      ))}
    </Select>
  );
}

WasteButton.propTypes = {
  sm: PropTypes.bool,
  select: PropTypes.string.isRequired,
  selectOpen: PropTypes.bool.isRequired,
  setSelectOpen: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
};

WasteButton.defaultProps = {
  sm: undefined,
};

export default WasteButton;
