import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { baseAxios } from "../../utils/axios";

export const fetchFeed = createAsyncThunk("feed/fetchEvents", async (props) => {
  const { params } = props;
  delete params.display;

  try {
    const result = await baseAxios.get("api/feed", { params });
    return result.data;
  } catch (err) {
    throw err;
  }
});

const initialState = {
  status: "idle",
  events: [],
  count: 0,
  error: null,
};

export const feedSlice = createSlice({
  name: "feed",
  initialState,
  reducers: {
    updateEvent: (state, { payload: { id, ...params } }) => {
      const events = state.events.map((event) => {
        if (event.id === id) {
          return { ...event, ...params };
        } else return event;
      });

      state.events = events;
    },
    removeEvent: (state, { payload: { id } }) => {
      const events = state.events.filter((event) => event.id !== id);
      state.events = events;
    }
  },
  extraReducers: {
    [fetchFeed.pending]: (state) => {
      state.status = "loading";
    },
    [fetchFeed.fulfilled]: (state, { payload }) => {
      state.status = "succeeded";
      state.events = [...(payload?.results || [])];
      state.count = payload.count;
    },
    [fetchFeed.rejected]: (state, { payload }) => {
      state.status = "failed";
      state.error = payload;
    },
  },
});

export const selectFeed = (state) => state.feed;

export const { updateEvent, removeEvent } = feedSlice.actions;

export const selectEvents = (state) => state.feed.events;
export const selectEventsCount = (state) => state.feed.count;

export default feedSlice.reducer;
