import { baseAxios } from "../../../utils/axios";

const getEvents = async (
  param,
  setParam,
  setEvent,
  setPreviousEvent,
  setLoadingEvent,
  setLoadingPreviousEvent,
  setAlert
) => {
  const reset = () => {
    setLoadingEvent(false);
    setLoadingPreviousEvent(false);
  };
  try {
    const { status, data } = await baseAxios.get("api/verification/", {
      params: { first_event: param },
    });
    if (status === 200) {
      reset();
      setEvent(data.event);
      setPreviousEvent(data.previous_event);
    } else if (status === 204) {
      if (!param) {
        setAlert(true);
      }
      setParam(false);
    } else {
      setEvent(null);
      setPreviousEvent(null);
    }
  } catch (err) {
    throw new Error(err);
  }
};

export default getEvents;
