import {LEGEND_COLORS} from "./constants";
import { v4 as uuidv4 } from 'uuid';
import {COLORS} from "../../../constants/base";
import numberFormater from "../../../utils/formaters/numberFormater";

export const getLabels = (labels, legend, predefinedOptions) => labels.reduce((acc, item, index) => {
  acc[item] = {
    key: item,
    isActive: true,
    color: LEGEND_COLORS[index],
    id: uuidv4(),
    legend,
    ...(predefinedOptions ? predefinedOptions(item) : {}),
  }
  return acc
}, {})

export const getCornerFill = (activeLabels, index) => {
  const nextActive = activeLabels[index + 1]
  return nextActive?.color
}

export const mapChartLabels = notLabel => data => Object.keys(data[0]).filter(key => !notLabel.includes(key))

const normalizeDataForChart = fieldName => data => Object.keys(data).reduce((acc, item) => {
  if(Array.isArray(data[item])) {
    let meta = {}
    data[item].forEach(({name, value, rolling_value, ...rest}) => {
      acc[name] = rolling_value || value
      meta[name] = rest
      meta[name].originalValue = value
    })
    acc.meta = meta
    return acc
  }
  if( item === 'y') {
    acc[fieldName || 'Waste'] = data[item]
    return acc
  }
  acc[item] = data[item]
  return acc
}, {})
export const exploreDataToChartData = (data, fieldName) => data.map(normalizeDataForChart(fieldName))

export const getDiff = (meta, labels) => {
  const activeKeys = labels.map(item => item.key)
  if (!meta) return
  const total = Object.keys(meta).reduce((acc, item) => {
    if (activeKeys.includes(item)) {
      acc += meta[item].diff
    }
    return acc
  }, 0)
  return total / labels.length
}

export const getDiffColor = diff => diff > 0 ? COLORS.red : diff === 0 ? COLORS.yellow : COLORS.green
export const getDiffText = (diff, format) => `${diff > 0 ?  '↑' : '↓'} ${format ? format(diff) : numberFormater({ value: diff, fixed: 2 })}%`

export const exploreDataToTableData = data => data.reduce((acc, item) => {
  const row = { date: item.x }
  if(Array.isArray(item.y)) {
    item.y.forEach(label => acc.push({...row, ...label, id: uuidv4()} ))
    return acc
  }
  acc.push({...row, value: item.y, diff: item.diff, id: uuidv4() })
  return acc
}, [])

export const getPath = (x, y, width, height, isVertical, radius, isStack, reduceRadius) => {
  let path = '';

  if (reduceRadius) {
    if (width < radius * 2) {
      radius = width / 2;
    }
  }

  // Start at top-left corner
  path += `M ${x},${y}`;

  // Line to top-right corner, rounding if necessary
  path += ` L ${x + width - radius},${y}`;

  // Arc for top-right corner if rounding top-right or both
  path += ` A ${radius},${radius} 0 0 1 ${x + width},${y + radius}`;

  // Line to bottom-right corner, rounding if necessary
  path += isVertical
    ? ` L ${x + width},${y + height - radius }`
    : isStack
      ? ` L ${x + width},${y + height + radius}`
      : ` L ${x + width},${y + height}`;

  // Arc for bottom-right corner if rounding bottom-right or both
  if (isVertical) {
    path += ` A ${radius},${radius} 0 0 1 ${x + width - radius},${y + height}`;
  }
  if(isStack) {
    if (!isVertical) {
      path += ` A ${radius},${radius} 0 0 0 ${x + width - radius},${y + height}`;
      path += ` L ${x + radius},${y + height}`;
      path += ` A ${radius},${radius} 0 0 0 ${x},${y + height + radius}`;
      path += ` L ${x},${y + radius}`;
      path += ` A ${radius},${radius} 0 0 1 ${x + radius},${y}`;
      path += ' Z'; // Close the path
      return  path
    } else {
      path += ` L ${x - radius}, ${y + height}`
      path += ` A ${radius},${radius} 0 0 0 ${x},${y + height - radius}`
      path += ` L ${x},${y + radius}`;
      path += ` A ${radius},${radius} 0 0 0 ${x - radius},${y}`;
      path += ' Z'; // Close the path
    }
  }

  // Line to bottom-left corner, rounding if necessary
  path += ` L ${x},${y + height}`;

  // Line back to top-left corner, rounding if necessary
  path += !isVertical ? ` L ${x},${y + radius}` : ` L ${x},${y}`;

  // Arc for top-left corner if rounding top-left or both, and close the path
  if (!isVertical) {
    path += ` A ${radius},${radius} 0 0 1 ${x + radius},${y}`;
  }

  path += ' Z'; // Close the path

  return path;
};


export const getGroundPath = (x, y, radius, isVertical) => {
  const width = radius
  const height = radius
  const axisGap = 0.5
  const xPos = isVertical ? x - width - axisGap  : x - width / 2
  const yPos = isVertical ? y - width / 2 : y + axisGap
  return (
    `M ${xPos},${yPos} L ${xPos + width},${yPos} L ${xPos + width},${yPos + height} L ${xPos},${yPos + height} Z`
  )
}


