import React, {useEffect, useMemo} from 'react';
import {Divider, FormControl, InputLabel, MenuItem, Select, Stack, Typography} from "@mui/material";
import {useFormik} from "formik";
import Button from "@mui/material/Button";
import DateRangeInput from "../inputs/DateRangeInput";
import useAppSelector from "../../hooks/useAppSelector";
import {
  LABELS,
  LABELS_LABEL,
  METRICS,
  METRICS_LABEL
} from "../../constants/explore";
import ReviewSelectStandard from "../inputs/ReviewSelectStandard";
import {useExploreFilters} from "./useExploreFilters";
import ExportButton from "./ExportButton";
import {useTheme} from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useDispatch, useSelector} from "react-redux";
import {fetchFoods, selectFoods} from "../../redux/foods";
import {selectSites} from "../../redux/sites";
import {ALL_SITES} from "../../constants/widgets";
import {selectFocusFood} from "../../redux/profile";
import SimpleDialog from "../dialogs/SimpleDialog";
import ConfirmModal from "../../layouts/ConfirmModal";
import {useModal} from "mui-modal-provider";
import constants from '../filter/configs/constants'
import {selectZones} from "../../redux/zones";
const FOCUS_TITLE = 'Please go to the home page to set your focus foods'

const Filter = ({ closeModal }) => {
  const { filters, updateFilters } = useExploreFilters()
  const { sites } = useAppSelector(selectSites)
  const { foods } = useAppSelector(selectFoods)
  const focusFood = useAppSelector(selectFocusFood)
  const { showModal } = useModal()
  const { zones } = useSelector(selectZones);
  const { values, handleChange, setFieldValue, handleSubmit} = useFormik({
    initialValues: {
      metric: filters.metric,
      labels: filters.labels,
      dateRange: filters.dateRange,
      sites: filters.sites,
      food: filters.food,
      zones: filters.zones,
    },
    onSubmit: value => {
      updateFilters(value)
      if(closeModal) {
        closeModal()
      }
    },
  })
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const dispatch = useDispatch()
  const siteFocusFood = useMemo(() => {
    const selectedSite = values.sites[0]
    if (selectedSite) {
      return sites.find(site => site.id === selectedSite.id)?.focus_food || []
    }
    return []
  }, [values.sites[0]?.id, sites])


  const handleDateRangeChange = value => {
    setFieldValue('dateRange', value)
    dispatch(fetchFoods({ date: value }))
  }

  const showFocusModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <ConfirmModal
        onClose={() => targetModal.hide()}
        onConfirm={() => targetModal.hide()}
        confirmText={'Ok'}
        title={FOCUS_TITLE}
        closeModal={() => targetModal.hide()}
        info
      />,
    })
  }

  useEffect(() => {
    setFieldValue('dateRange', filters.dateRange)
  }, [filters.dateRange]);

  useEffect(() => {
    if (values.labels === LABELS.focusFood && !(focusFood.length || siteFocusFood.length)) {
      showFocusModal()
    }
  }, [values.labels, focusFood.length, siteFocusFood.length])

  useEffect(() => {
    if(values.metric === METRICS.occupancy) {
      setFieldValue('labels', 'none')
    }
  }, [values.metric]);

  const handleSiteChange = event => {
    const site = sites.filter(site => site.id === event.target.value)
    setFieldValue('sites', site)
  }

  const isLabelsAvailable = values.metric !== METRICS.occupancy

  return (
    <Stack spacing={5}>
      <Typography variant="medium">Filters</Typography>
      <Divider />
      <form onSubmit={handleSubmit}>
        <Stack spacing={5}>
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="metric">Metric</InputLabel>
            <Select
              id="metric"
              name="metric"
              value={values.metric}
              onChange={handleChange}
            >
              {Object.values(METRICS).map(metric => (
                <MenuItem key={metric} value={metric}>{METRICS_LABEL[metric]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl variant="outlined" fullWidth disabled={!isLabelsAvailable}>
            <InputLabel id="metric">Labels</InputLabel>
            <Select
              id="labels"
              name="labels"
              value={values.labels}
              onChange={handleChange}
            >
              {Object.values(LABELS).map(label => (
                <MenuItem key={label} value={label}>{LABELS_LABEL[label]}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <DateRangeInput
            value={values.dateRange}
            outlined
            setValue={handleDateRangeChange}
            onClean={() => setFieldValue('dateRange', [])}
            label="Select Date"
            variant="outlined"
            ranges={isMobile ? [] : undefined}
          />
          <FormControl variant="outlined" fullWidth>
            <InputLabel id="sites">Select Site</InputLabel>
            <Select
              id="sites"
              name="sites"
              value={values.sites[0]?.id || ALL_SITES}
              onChange={handleSiteChange}
            >
              <MenuItem value={ALL_SITES}>All Sites</MenuItem>
              {sites.map(site => (
                <MenuItem key={site.id} value={site.id}>{site.name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <ReviewSelectStandard
            fullWidth
            label={constants.zone.label}
            multiple
            variant="outlined"
            setValue={val => setFieldValue('zones', val)}
            value={values.zones}
            items={zones}
            placeholder="All"
          />
         <ReviewSelectStandard
           fullWidth
           label="Food"
           multiple
           variant="outlined"
           setValue={val => setFieldValue('food', val)}
           value={values.food}
           items={foods}
           placeholder="All"
           />
          <Button type="submit" fullWidth variant="contained" color="secondary">Apply Filters</Button>
        </Stack>
      </form>
      {!isMobile && (
        <>
          <Divider />
          <ExportButton />
        </>
      )}
    </Stack>
  );
};

export default Filter;
