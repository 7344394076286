import React, {useState} from 'react';
import {Box, Card, Divider, IconButton, Stack, Typography} from "@mui/material";
import styled from "@emotion/styled";
import useAppDispatch from "../../hooks/useAppDispatch";
import {unpinWidget} from "../../redux/widgets";
import useIsMobile from "../../hooks/useIsMobile";
import Tooltip from "../tooltip";
import {useSnackbar} from "notistack";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { useTheme } from "@mui/material/styles";
const Footer = ({ children }) => {
  return (
    <Stack>
      <Divider />
      <Box sx={{ padding: '15px 30px'}}>
        {children}
      </Box>
    </Stack>
  )
}

const Content = ({ children, style }) => {
  const isMobile = useIsMobile()
  return (
    <Box sx={{ padding: '15px 30px', minHeight: isMobile ? 'unset' : '280px', position: 'relative', ...style}} flex={1} overflow={"hidden"}>
      { children }
    </Box>
  )
}

const UnpinContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  cursor: pointer;
`
const UnpinButton = ({ id }) => {
  const dispatch = useAppDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const handleUnpin = () => dispatch(unpinWidget(id))
    .unwrap()
    .then(res => res.error && enqueueSnackbar('Can not unpin widget', { variant: 'error' }))
  return (
    <UnpinContainer onClick={handleUnpin}>
      <Typography color="white" fontWeight={700}>-</Typography>
    </UnpinContainer>
  )
}


const Base = ({ title, tooltip, value, children, id, canUnpin, withoutHeader }) => {
  const isMobile = useIsMobile()
  const [isHovered, setIsHovered] = useState(false)
  const titleType = 'subtitle'
  const theme = useTheme()
  return (
    <Card
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      sx={{ position: "relative", overflow: 'visible', height: '100%' }}
    >
      <Stack sx={{ height: '100%' }}>
        {!withoutHeader && (
          <>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ padding: '15px 30px' }}>
              <Stack direction="row" spacing={4} overflow="hidden" alignItems="center">
                <Tooltip style={{ minWidth: 0 }} title={title}>
                  <Typography textOverflow="ellipsis" variant={titleType} whiteSpace="nowrap" overflow="hidden" component="h3">{title}</Typography>
                </Tooltip>
                {tooltip && (
                  <Tooltip title={tooltip} placement="top">
                    <IconButton>
                      <FontAwesomeIcon size="xs" color={theme.palette.text.primary} icon={faCircleInfo} width={20} height={20} />
                    </IconButton>
                  </Tooltip>
                )}
              </Stack>
              {!!value &&  <Typography whiteSpace="nowrap" variant={titleType} fontWeight={700}>{value}</Typography>}
            </Stack>
            <Divider/>
          </>
        )}
        {children}
      </Stack>
      {(isHovered || isMobile) && canUnpin && <UnpinButton id={id}/>}
    </Card>
  );
};

Base.Footer = Footer
Base.Content = Content

export default Base;
